import React, { PureComponent } from 'react'
import { Table, Space, Popconfirm, Tag } from 'antd'
import { connect } from 'react-redux'
import action from '../../store/actions/action'

let numIndex = ["一", "二", "三", "四", "五", "六"]
class InterestTable extends PureComponent {

    columns = [
        {
            title: '名称',
            dataIndex: 'name'
        },
        {
            title: '所属年级',
            dataIndex: 'grade',
            render: (record) => {
                let str = ''
                record.map(item => str = str + numIndex[item] + ',')
                str = str.substring(0, str.length-1)
                str += '年级'
                return str
            }
        },
        {
            title: '上课时间',
            dataIndex: 'weeks',
            render: (record) => {
                let str = ''
                record.map(item => str = str + numIndex[item] + ',')
                str = str.substring(0, str.length-1)
                str = '周' + str
                return str
            }
        },
        {
            title: '设备编码',
            dataIndex: 'code'
        },
        {
            title: '操作',
            key: 'action',
            render: (_text, record) => (
                <Space size="middle">
                    <a onClick={() => this.editInterest(record, 'edit')}>编辑</a>
                    <Popconfirm
                        title='确认要删除改记录？'
                        okText='是'
                        cancelText='否'
                        onConfirm={() => this.editInterest(record, 'delete')}
                        onCancel={this.onCancel}>
                        <a href='#'>删除</a>
                    </Popconfirm>
                </Space>
            ),
        },
    ]
    constructor(props) {
        super(props)
        this.state = {
            orgList: []
        }
    }
    componentDidMount() {
    }
    testSendMessage = async (tag, behavior) => {
        let result = await this.$axios.post('/pushUniform', { code: tag, devid: this.state.cursel, orgid: this.props.organization.id, behavior: behavior })
    }
    editInterest = (value, op) => {
        this.props.edit(value, op)
    }
    onChange = (page, pageSize) => {
        console.log('page index', page, pageSize)
        this.setState({pageSize: page.pageSize})
        this.props.pageChange(page)
    }
    
    render() {
        return (
            <>
                <Table
                    columns={this.columns}
                    dataSource={this.props.orderlist}
                    showSizeChanger
                    showQuickJumper
                    onChange={this.onChange}
                    pagination={{
                        pageSize: this.props.pageSize,
                        total: this.props.total,
                        // hideOnSinglePage: true,
                        showSizeChanger: true,
                        showQuickJumper: true
                    }}
                />
            </>
        )
    }
}

const mapStateToProps = state => {
    return {
        organization: state.get('organization')
    }
}
export default connect(mapStateToProps, action, null, { forwardRef: true })(InterestTable)