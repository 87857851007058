import React, { PureComponent } from 'react'
import { message } from 'antd'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import action from '../../store/actions/action'
import { Form, Input, Button, Select } from 'antd'
import './login.css'

const { Option } = Select
const layout = { labelCol: { span: 6 }, wrapperCol: { offset: 2, span: 12 } }

const tailLayout = {
    wrapperCol: {
        offset: 8,
        span: 12
    }
}

class Login extends PureComponent {

    formRef = React.createRef()
    constructor(props) {
        super(props)
        this.state = {
            save: false,
            type: 'account'
        }
    }
    onChange = e => {
        this.setState({ save: e.target.checked })
    }
    componentDidMount() {
        // console.log('item', window.localStorage.getItem('auto-login'))
        // window.localStorage.getItem('auto-login') === 'yes' && this.props.history.push('/')
        // window.sessionStorage.getItem('auto-login') === 'yes' && this.props.history.push('/')
    }

    onRoleChange = val => (val == 1) && this.formRef.current.setFieldsValue({ user: 'admin' })

    changeLogin = () => {
        let type = this.state.type == 'account' ? 'scan' : 'account'
        this.setState({ type })
    }
    onFinish = (values) => {
        let params = {
            userid: values.user,
            password: values.password,
            type: values.role
        }
        console.log(params, 'validate')
        React.$axios.post('admin/validate', params).then(res => {
            if (res.code == 0) {
                this.props.setLoginInfo(params)
                if (this.state.save === true) {
                    window.localStorage.setItem('auto-login', 'yes')
                    window.localStorage.setItem('login-info', JSON.stringify(params))
                } else {
                    window.sessionStorage.setItem('auto-login', 'yes')
                    window.sessionStorage.setItem('login-info', JSON.stringify(params))
                }
                message.success('登录成功')
                if (values.role == 1) {
                    this.props.changeMenuSelect('main')
                    this.props.history.push('/')
                } else if (values.role == 2) {
                    this.props.saveOrganization(res.data)
                    this.props.changeMenuSelect('student')
                    this.props.history.push('/student')
                    window.localStorage.setItem('organization', JSON.stringify(res.data))
                } else if (values.role == 3) {
                    this.props.saveOrganization(res.data)
                    this.props.changeMenuSelect('leave')
                    this.props.history.push('/leave')
                    window.localStorage.setItem('organization', JSON.stringify(res.data))
                }
            } else {
                message.error('登录失败')
            }
        }).catch(err => {
            console.log('error', err)
            message.error('登录失败')
        })
    }

    render() {
        return (
            <div style={{ display: 'flex', height: '100%' }}>
                <div style={{ width: '50%', padding: 40 }}>
                    <img src='imgs/background.png' style={{ width: '100%', height: '100%' }} />
                </div>
                <div style={{ flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                    <div style={{ margin: '0 auto', position: 'relative' }}>
                        <div onClick={this.changeLogin} style={{ position: 'absolute', right: 0, top: 0, fontSize: 32, color: 'blue', cursor: 'pointer' }}>
                        </div>
                        <div style={{ textAlign: 'center' }}>
                            <span className="title-style" >安全接送管理系统</span>
                        </div>
                    </div>
                    <Form {...layout} ref={this.formRef} style={{ width: 400, height: 400, paddingTop: 60 }} onFinish={this.onFinish}>
                                    <Form.Item
                                        name="user"
                                        label={'用户名'}
                                        rules={[
                                            { required: true, message: '用户名不能为空' }
                                        ]}>
                                        <Input />
                                    </Form.Item>
                                    <Form.Item
                                        name="password"
                                        label={'密码'}
                                        rules={[
                                            { required: true, message: '密码不能为空' }
                                        ]}>
                                        <Input type="password" />
                                    </Form.Item>
                                    <Form.Item name="role"
                                        label="用户类型"
                                        rules={[
                                            { required: true, message: '请选择用户类型' }
                                        ]}>
                                        <Select
                                            onChange={this.onRoleChange}
                                            placeholder="选择角色"
                                            allowClear>
                                            <Option key={1} value={1}>系统管理员</Option>
                                            <Option key={2} value={2}>学校管理员</Option>
                                            <Option key={3} value={3}>学校操作员</Option>
                                            {/* <Option key={3} value={3}>教师</Option><Option key={4} value={4}>家长</Option> */}
                                        </Select>
                                    </Form.Item>
                                    <Form.Item {...tailLayout}>
                                        <Button type="primary" htmlType="submit">登录</Button>
                                    </Form.Item>
                                </Form >
                </div>
            </div>
        )
    }
}
const mapStateToProps = (state) => {
    return {
        conid: state.get('conid'),
        login: state.get('auth')
    }
}

export default connect(mapStateToProps, action)(withRouter(Login))