import React from 'react'
import { connect } from 'react-redux'
import action from '../../store/actions/action'
import { Modal, Form, Select, message, Button, Input } from 'antd'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import store from '../../store'
import CheckTable from '../../components/tables/CheckTable'

const { Option } = Select

class Check extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            isVisible: false,
            type: 'create',
            item: '',
            who: 'beton',
            betonlist: [],
            partlist: [],
            showlist: [],
            stulist: [],
            modal2Visible: false,
            cursel: 0,
            curstu: '',
            StudentList: [],
            keyword: ''
        }
        this.props.changeMenuSelect('student')
    }
    componentDidMount = async () => {

        let tmplist = []
        let res = await this.$axios.get('/grade/list')
        console.log('res.data', res.data)

        res.data.map(item => tmplist.push(item))
        this.setState({ betonlist: tmplist })
    }
    keywordChange = e => {
        this.setState({ keyword: e.target.value })
    }
    addMark = (who, type, item) => {
        if (who == 'part') {
            if (this.state.cursel) {
                this.setState({ isVisible: true, type, item, who })
            } else {
                message.warn('请选择一个类别')
            }
        } else {
            this.setState({ isVisible: true, type, item, who })
        }
    }
    delMark = (type, item) => {
        let _this = this;
        Modal.confirm({
            title: '确定要删除？',
            icon: <ExclamationCircleOutlined />,
            content: `该操作将删除${item}，请谨慎操作！`,
            okText: '确定',
            okType: "danger",
            cancelText: '取消',
            onOk() {
                let getUserData = { type: type, operate: 'del', name: encodeURIComponent(item), old: '' }
                _this.props.sendDatadata(getUserData);
            },
            onCancel() { }
        });
    }
    
    btclose = () => {
        this.setState({ isVisible: false })
    }
    addProvidesUser = () => {
        store.dispatch({ type: 'CHANGE_SHOW_ONCE', payload: true })
        this.setState({ modal2Visible: true, prjtype: 'create', prjitem: '-' })
    }
    myCancel = (modal2Visible) => {
        this.ref.clearValues()
        this.setState({ modal2Visible })
    }
    setModal2Visible = () => {
        let vl = this.ref.getValues()
        if (vl.name !== '') {
            let getUserData = {
                type: 'provides',
                operate: this.state.prjtype === 'create' ? 'add' : 'mod',
                ...vl
            }
            this.props.sendDatadata(getUserData)
            this.ref.clearValues()
            this.setState({ modal2Visible: false })
        } else {
            Modal.error({
                title: '操作失败',
                content: '名称不能为空！',
                okText: '确定'
            })
        }
    }
    edtfun = (value) => { this.setState({ modal2Visible: true, prjtype: 'edit', prjitem: value }) }
    delfun = (value) => {
        let _this = this;
        Modal.confirm({
            title: '确定要删除？',
            icon: <ExclamationCircleOutlined />,
            content: `该操作将删除${value.name}，请谨慎操作！`,
            okText: '确定',
            okType: "danger",
            cancelText: '取消',
            onOk() {
                let getUserData = { type: 'provides', operate: 'del', key: value.key };
                _this.props.sendDatadata(getUserData);
            },
            onCancel() { }
        })
    }
    searchFun = async () => {
        if (this.state.curstu) {
            let result = await this.$axios.post('/inform/single', { invite: this.state.curstu, name: this.state.keyword })
            let res = result.data
            // console.log(res, 'searchF')
            res.forEach(item => item.key = item.id)
            // if (this.state.keyword !== '') {
            //   res = res.filter(it => {
            //     if (it.name.indexOf(this.state.keyword) != -1 || it.phone.indexOf(this.state.keyword) != -1) {
            //       return it
            //     }
            //   })
            // }
            this.setState({ StudentList: res || [] })
        } else {
            message.info('请选择班级！')
        }
    }
    render() {
        return (
            <div style={{ display: 'flex', height: '100%', width: '100%' }}>
                <div style={{ flex: 1 }}>
                    <Form
                        style={{ justifyContent: 'flex-end', marginBottom: '8px' }}
                        layout={'inline'}
                        name="control-hooks"
                        hideRequiredMark={true}
                        onFinish={this.showOderList}
                    >
                        <Form.Item
                            name='grade'
                            label="类别"
                            dataSource={this.state.betonlist}>
                            <Select
                                style={{ width: 120, marginRight: 16 }}
                                placeholder="类别"
                                allowClear
                                onChange={
                                    value => {
                                        this.setState({ cursel: value },
                                            () => {
                                                let showlist = this.state.partlist.filter(item => item.gradeid === value)
                                                this.setState({ stulist: showlist })
                                            }
                                        )
                                    }}>
                                {
                                    this.state.betonlist.map((item, index) => <Option key={item.id} value={item.id}>{item.name}</Option>)
                                }
                            </Select>
                        </Form.Item>

                        <Form.Item
                            name='clazz'
                            label="班级"
                        >
                            <Select style={{ width: 120 }}
                                placeholder="班级"
                                allowClear
                                onChange={
                                    value => {
                                        this.setState({ curstu: value })
                                    }}>
                                {
                                    this.state.stulist.map((item, index) => <Option key={item.id} value={item.invite}>{item.name}</Option>)
                                }
                            </Select>
                        </Form.Item>

                        <Form.Item
                            name='range'
                            label="查询学生"
                        >
                            <Input placeholder="请输入关键字查询" onChange={this.keywordChange} />
                        </Form.Item>

                        <Form.Item>
                            <Button type="primary" onClick={() => this.searchFun()}>查询</Button>
                        </Form.Item>
                    </Form>

                    <div className="radius-style shadow-style" style={{ padding: 8 }} >
                        <CheckTable
                            pageSize={this.state.pageSize}
                            total={this.state.total}
                            orderlist={this.state.StudentList}
                            recFinish={this.recFinish} />
                    </div>
                </div >
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        cursel: state.get('cursel')
    }
}
export default connect(mapStateToProps, action)(Check);