import React, { PureComponent } from 'react'
// import 'antd/dist/antd.css'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import action from '../../store/actions/action'
import { Player } from 'video-react'
import "video-react/dist/video-react.css"
import TimeUtils from '../../utils/time'
import * as types from '../../store/types/type'
import store from '../../store'
import './index.css'
import Marquee from '../../components/other/marquee'

let light = { width: '60px', height: '60px', borderRadius: '50%', border: '4px solid #0033FF', backgroundColor: '#00CCFF' }
let off = { width: '60px', height: '60px', borderRadius: '50%', border: '4px solid #999999', backgroundColor: '#666666' }
let gradeList = ["一年级", "二年级", "三年级", "四年级", "五年级", "六年级"]
class Play extends PureComponent {

	formRef = React.createRef()
	mess = React.createRef()
	pictTimeCn = 0
	curVideoIdx = 0
	curPictureIdx = 0
	curProIdx = 0
	doOnce = false

	constructor(props) {
		super(props)
		this.state = {
			progList: '',
			preview: 0,
			videoSrc: '',
			pictureSrc: '',
			vcn: '',
			hcn: '',
			grade: [],
			max: 0,
			clazz: [],
			nowDate: '',
			nowWeek: '',
			organizations: [],
			saveOrg: '',
			type: 1,
			otv: '',
			oth: '',
			delayShow: [],
			subOnce: false,
			mess_right: 64,
			lists: '',
			reloadEn: false,
			timeList: [],
			enLeave: false,
			enPro: false
		}
	}

	componentDidMount = async () => {
		setInterval(() => {
			if (!this.state.reloadEn) {
				store.dispatch({type: types.ERROR_CN, payload: this.props.error_cn+1})
				if (this.props.error_cn > 5) {
					//store.dispatch({type: types.ERROR_CN, payload: 0})
					this.setState({ reloadEn: true })
				} 
		 	} else {
				this.props.connectWebsocket()
				if (this.props.error_cn == 0)	window.location.reload()
			}
		}, 1000)
		let info = this.props.location

		let requestParams = {}
		let str = info.search.substr(info.search.indexOf("?") + 1) //截取?后面的内容作为字符串
		let strs = str.split("&"); //将字符串内容以&分隔为一个数组
		for (let i = 0; i < strs.length; i++) {
			requestParams[strs[i].split("=")[0]] = decodeURI(strs[i].split("=")[1])
		}
		let account = requestParams['account'] //info.search.split('=')[1]

		try {
			let organizations = await this.$axios.get('/organization/all')
			if (organizations.code == 0) this.setState({ organizations: organizations.data })
			let org = this.state.organizations.find(it => {
				if (it.account == account) {
					return it
				}
			})
			if (org) {
				this.setState({ type: org.face, saveOrg: org.name })
				let recList = await this.$axios.get('/recover/list/' + account)
				if (recList.code == 0) {
					this.setState({ timeList: recList.data })
				}

				let program = await this.$axios.get('/program/action/' + account)
				if (program.code == 0) {
					this.setState({ progList: program.data })
				}
				let gradeList = await this.$axios.get('/grade/list/' + account)

				if (gradeList.code == 0) {
					let grade = JSON.parse(gradeList.data.grade)
					let tmp = JSON.parse(JSON.stringify(grade))
					tmp.sort((a, b) => b - a)
					let max = tmp[0]
					if (this.state.type == 0) {
						let vcn = '180px repeat(' + max + ', 1fr)'
						let hcn = '132px repeat(6, 1fr)'
						grade.unshift("0")
						this.setState({ grade: grade, vcn: vcn, hcn: hcn, max: max })
					} else {

						let oth = 'repeat(6, 1fr)'
						let otv = 'repeat(16, 1fr)'
						this.setState({ grade: grade, oth: oth, otv: otv, max: max })
					}
					if (gradeList.data.once) { this.setState({ lists: gradeList.data.message }) }

					let getLeave = await this.$axios.get('getLeave/' + account)
					if (getLeave.code == 0) {
						let tmp = getLeave.data.map(it => {
							it = it.map(s => s = Math.floor(Math.random() * 3))
							return it
						})
						this.setState({ clazz: getLeave.data, delayShow: getLeave.data })

						this.listener = store.subscribe(() => {
							let leave = store.getState().get('turn_leave')
							let clear = store.getState().get('clear')
							let screen = store.getState().get('screen_change')
							let message = store.getState().get('message')
							let web = store.getState().get('web_status')
							let select = store.getState().get('select_who')

							if (Object.keys(leave).length > 0 && select == 'turn') {
								if (leave.account == account) {
									if (leave.turn == '1') {
										let tmp = JSON.parse(JSON.stringify(this.state.clazz))
										tmp[parseInt(leave.grade)][parseInt(leave.level)] = '1'
										this.setState({ clazz: tmp }, () => this.computeDelay() )
									} else if (leave.turn != '0') {
										let tmp = JSON.parse(JSON.stringify(this.state.clazz))
										tmp[parseInt(leave.grade)][parseInt(leave.level)] = leave.time
										this.setState({ clazz: tmp }, () => this.computeDelay() )
									}
								}
							}
							if (Object.keys(clear).length > 0 && select == 'clear') {
								if (clear.account == account) {
									let tmp = this.state.clazz.map(it => {
										it = it.map(s => {
											s = '0'
											return s
										})
										return it
									})
									this.setState({ clazz: tmp })
								}
							}
							// if (select == 'screen') {
							// 	if (screen.account == account) {
							// 		this.setState({ progList: screen })
							// 		this.doLeave()
							// 	}
							// }
							if (select == 'message') {
								if (message.account == account) {
									this.setState({ lists: message.message })
								}
							}
						})
		
						setInterval(() => {
							this.setState({ nowDate: TimeUtils.formatDate(new Date()), nowWeek: TimeUtils.getWeek(new Date()) })
							// if (this.state.preview == '2') {
							// 	this.pictTimeCn++
							// 	if (this.pictTimeCn == 5) {
							// 		this.curProIdx++
							// 		this.pictTimeCn = 0
							// 		if (this.curProIdx == this.state.progList.length) {
							// 			this.curProIdx = 0
							// 		}
							// 		//this.setState({ preview: '2', pictureSrc: this.state.progList[this.curProIdx].path })
							// 		this.doLeave()
							// 	}
							// }
							this.computeDelay()
						}, 1000)
						
						
						this.props.connectWebsocket()
						setInterval(() => { this.props.sendData({ account: account, mac: requestParams['mac'] || '', type: 'web', order: 'heart' }) }, 1000)

						// setInterval(() => {
							
						// 	let isin = false
						// 	for (let i = 0; i < this.state.timeList.length; i++) {
						// 		let segment = this.state.timeList[i].segment.split(' ')
						// 		if (TimeUtils.getTime() >= segment[0] && TimeUtils.getTime() < segment[1]) {
						// 			isin = true
						// 			if (!this.state.enLeave) {
						// 				if (this.player) this.player.pause()
						// 				this.curProIdx = 0
						// 				this.setState({ enLeave: true, enPro: false, preview: '0' })
						// 				break
						// 			}
						// 		}
						// 	}
						// 	if (!isin) {
						// 		if (!this.state.enPro) {
						// 			this.setState({ enLeave: false, enPro: true })
						// 			this.curProIdx = 0
						// 			setTimeout(() => this.doLeave(), 2000)
						// 		}
						// 	}
						// }, 1000)
					} else {
						this.setState({ reloadEn: true })
					}
				} else {
					this.setState({ reloadEn: true })
				}
				
			} else {
				this.setState({ reloadEn: true })
			}
		} catch (e) {
			this.setState({ reloadEn: true })
		}
	}

	computeDelay() {
		let d = JSON.parse(JSON.stringify(this.state.clazz))
		if (d.length > 0) {
			for (let i = 0; i < 6; i++) {
				for (let j = 0; j < this.state.clazz[i].length; j++) {
					if (parseInt(this.state.clazz[i][j]) > 1) {
						let diff = Number(this.state.clazz[i][j]) - Number(new Date().getTime())
						let c = (diff / 60000).toFixed(0)
						
						if (c > 0) {
							d[i][j] = c
						} else {
							if (diff <= 0)
								d[i][j] = '0'
							else
								d[i][j] = '1'
						}
					}
				}
			}
			this.setState({ delayShow: d })
		}
	}

	doLeave = () => {
		let leave = this.state.progList[this.curProIdx]
		if (leave) {
			if (leave.type == 'video') {
				
				this.setState({ preview: '1', videoSrc: leave.path }, () => {
					if (this.player) {
						this.player.load()
						this.player.play()
						this.doOnce = false
						if (!this.subOnce) {
							this.setState({ subOnce: true })
							this.player.subscribeToStateChange(this.handleStateChange.bind(this))
						}
					}
				})

			} else if (leave.type == 'picture') {
				try {
					this.player.pause()
				} catch (error) {
				}
				this.setState({ pictureSrc: leave.path }, () => setTimeout(() => {
					this.setState({ preview: '2' })
				}, 1000))
			} else {
				try {
					this.player.pause()
				} catch (error) {
				}
				this.setState({ preview: '0' })
			}
		}
	}

	componentWillUnmount() {
		this.listener()
		this.props.disconnect()
	}

	handleStateChange(state, prevState) {
		if (state.currentTime == state.duration) {
			if (!this.doOnce) {
				this.doOnce = true
				let idx = this.curProIdx + 1
				if (idx == this.state.progList.length) {
					idx = 0
				}
				this.curProIdx = idx
				this.doLeave()
			}
		}
	}

	render() {
		return (
			<div style={{ backgroundColor: 'black', width: '100%', height: '100%', position: 'relative' }}>
				{
					this.state.reloadEn ?
						<div style={{ position: 'absolute', fontSize: '48px', backgroundColor: '#999', color: '#f00', width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
							{/* <img src={errorImg} style={{ width: '100%', height: '100%', overflow: 'hidden' }} /> */}
							网络连接失败~~~
						</div>
						:
						this.state.type == 0
							?
							<div style={{ backgroundColor: '#4B4C4D', width: '100%', height: '100%', display: 'flex', flexDirection: 'column', visibility: this.state.preview == 0 ? 'visible' : 'hidden' }} >
								<div style={{ height: '142px', display: 'flex', alignItems: 'center' }}>
									<div style={{ fontSize: '40px', fontWeight: '900', backgroundColor: '#D8D8D8', padding: '6px 66px 6px 40px', borderTopRightRadius: '30px', borderBottomRightRadius: '30px' }}>
										{this.state.saveOrg}放学信息平台
									</div>
									<div style={{ flex: 1, textAlign: 'right', marginRight: '54px', color: '#ffffff', display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
										<div style={{ fontSize: '38px', fontWeight: '500', fontFamily: '宋体', marginRight: '28px' }}>{this.state.nowDate}</div>
										<div style={{ fontSize: '28px', fontWeight: '500', fontFamily: '微软雅黑' }}>{this.state.nowWeek}</div>
									</div>
								</div>
								<div style={{ margin: '0 32px 64px 32px', flex: 1, display: 'grid', gridTemplateColumns: this.state.vcn, gridTemplateRows: this.state.hcn }} >
									{
										this.state.grade.map((item, index) => {
											return <>
												{
													Array.from({ length: parseInt(this.state.max) + 1 }).map((it, idx) => {
														return <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
															{
																index != 0 ? idx == 0 ? <div className='grade'>{gradeList[index - 1]}</div> : idx - 1 < this.state.grade[index] ? <div style={this.state.clazz.length > 0 && this.state.clazz[index - 1][idx - 1] == '1' ? light : off}></div> : '' : idx != 0 ? <div className='clazz'>{idx}班</div> : ''
															}
														</div>
													})
												}
											</>
										})
									}
								</div>
							</div>
							:
							<div style={{ margin: '0 16px 30px 16px', backgroundColor: '#000', height: '100%', display: 'flex', flexDirection: 'column', visibility: this.state.preview == 0 ? 'visible' : 'hidden' }} >
								<div style={{ height: '10vh', display: 'flex', alignItems: 'center' }}>
									<div style={{ fontSize: '4.5vh', fontWeight: '800', fontFamily: 'PingFang-SC-Heavy, PingFang-SC', color: '#fff' }}>
										{this.state.saveOrg}放学信息平台
									</div>
									<div style={{ flex: 1, textAlign: 'right', color: '#ffffff', display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
										<div style={{ fontSize: '4.5vh', fontWeight: '800', fontFamily: 'PingFang-SC-Heavy, PingFang-SC', marginRight: '28px' }}>{this.state.nowDate}</div>
										<div style={{ fontSize: '4.5vh', fontWeight: '800', fontFamily: 'PingFang-SC-Heavy, PingFang-SC' }}>{this.state.nowWeek}</div>
									</div>
								</div>
								<div style={{ flex: 1, display: 'grid', gridTemplateColumns: this.state.oth, gridTemplateRows: this.state.otv, position: 'relative' }} >
									{
										Array.from({ length: 16 }).map((item, index) => {
											return <>
												{
													this.state.grade.map((it, idx) => {
														return <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
															{
																index == 0
																	?
																	<div className='otGrade'>{gradeList[idx]}</div>
																	:
																	index < parseInt(this.state.grade[idx]) + 1
																		?
																		<div className='otGrade otSub'>
																			<div>
																				{gradeList[idx].substr(0, 1)}({index})班
																			</div>
																			{
																				(this.state.clazz.length > 0) && (this.state.clazz[idx][index - 1] == '0' ? <div style={{ color: 'rgba(250, 255, 0, 1)' }}>上课中</div> : this.state.clazz[idx][index - 1] == '1' ? <div style={{ color: 'rgba(0, 255, 30, 1)' }}>已放学</div> : <div style={{ color: 'rgba(255, 0, 0, 1)' }}>{this.state.delayShow[idx][index - 1].length > 3 ? '' : this.state.delayShow[idx][index - 1] == '0' ? '上课中' : `晚${this.state.delayShow[idx][index - 1]}分钟`}</div>)
																			}
																		</div>
																		:
																		<div className='otGrade otSub' style={{ opacity: 0 }} />
															}
														</div>
													})
												}
											</>
										})
									}
								</div >

							</div>
				}
				<div style={{ position: 'absolute', width: '100%', height: '100%', top: 0, left: 0, visibility: this.state.preview == 1 ? 'visible' : 'hidden' }}>
					<Player autoPlay={true} ref={player => this.player = player} src={this.state.videoSrc} fluid={false} width={"100%"} height={"100%"} />
				</div>

				<div style={{ position: 'absolute', width: '100%', top: 0, left: 0, visibility: this.state.preview == 2 ? 'visible' : 'hidden' }}>
					<img src={this.state.pictureSrc} style={{ width: '100%', height: '100%', overflow: 'hidden' }} />
				</div>

				<div style={{ color: 'red', position: 'absolute', fontSize: '5.2vh', fontFamily: '黑体', fontWeight: '900', bottom: '84px', width: '100vw', backgroundColor: '#000', visibility: (this.state.preview == 1 || this.state.preview == 2 || this.state.reloadEn) ? 'hidden' : 'visible' }}>
					<Marquee lists={this.state.lists} />
				</div>

			</div>

			// <div className="login">
			// 	<div className="title">校园放学接送大屏</div>
			// 	<Card title='用户登录'>
			// 		<Form {...layout} ref={this.formRef} style={{ width: 400 }} onFinish={this.onFinish}>
			// 			<Form.Item name="user" label={'用户名'}>
			// 				<Input {...this.props}/>
			// 			</Form.Item>
			// 			<Form.Item name='pass' label="密码">
			// 				<Input {...this.props} type='password' />
			// 			</Form.Item>
			// 			<Form.Item {...tailLayout}>
			// 				<Button type="primary" htmlType="submit">登录</Button>
			// 			</Form.Item>
			// 		</Form >
			// 	</Card>
			// </div>
		)
	}
}

const mapStateToProps = state => {
	return {
		organization: state.get('organization'),
		error_cn: state.get('error_cn')
	}
}

export default connect(mapStateToProps, action)(withRouter(Play))
