import React, { useImperativeHandle, useEffect } from 'react'
import { Form, Input, Select } from 'antd'
import { connect } from 'react-redux'
import action from '../../store/actions/action'

const { Option } = Select
const layout = { labelCol: { span: 8 }, wrapperCol: { span: 10 } }
const gradelist = ["一年级", "二年级", "三年级", "四年级", "五年级", "六年级"]
const weekslist = ["星期一", "星期二", "星期三", "星期四", "星期五"]

const InterestForm = React.forwardRef((props, ref) => {

    const [form] = Form.useForm()

    useEffect(() => { form.setFieldsValue({ 
        name: !props.type ? props.item.name : '', 
        grade: !props.type ? props.item.grade : [], 
        weeks: !props.type ? props.item.weeks : [], 
        code: !props.type ? props.item.code : '' 
    }) })

    const onFinish = () => {
        let params = {
            name: form.getFieldValue('name'),
            grade: form.getFieldValue('grade'),
            weeks: form.getFieldValue('weeks'),
            code: form.getFieldValue('code')
        }
        !props.type && (params['id'] = props.item.id)
        props.emit(params)
    }
    useImperativeHandle(ref, () => ({
        resetFields: () => {
            form.resetFields()
        },
        refFinish: () => form.submit()
    }))
    return (
        <Form {...layout} form={form} preserve={false} name="control-hooks" requiredMark={true} onFinish={onFinish}>
            <Form.Item name="name" label="课程名称" rules={[{ required: true, message: '名字不能为空' }]}><Input /></Form.Item>
            <Form.Item name="grade" label="所属年级" rules={[{ required: true, message: '年级不能为空' }]}>
                <Select
                    allowClear
                    style={{ width: '100%' }}
                    mode="multiple"
                    placeholder="请选择">
                    { gradelist.map((item, index) => { return <Option key={index} value={item.id}>{item}</Option> })}
                </Select>
            </Form.Item>
            <Form.Item name="weeks" label="上课时间" rules={[{ required: true, message: '时间不能为空' }]}>
                <Select
                    allowClear
                    style={{ width: '100%' }}
                    mode="multiple"
                    placeholder="请选择">
                    { weekslist.map((item, index) => { return <Option key={index} value={item.id}>{item}</Option> })}
                </Select>    
            </Form.Item>
            <Form.Item name="code" label="设备编码" rules={[{ required: true, message: '编码不能为空' }]}><Input /></Form.Item>
        </Form>
    )
})

const mapStateToProps = state => {
    return {
        organization: state.get('organization')
    }
}
export default connect(mapStateToProps, action, null, { forwardRef: true })(InterestForm)