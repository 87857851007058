import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import action from '../../store/actions/action'
import OrganizationTable from '../../components/tables/organizationTable'
import OrganizationForm from '../../components/forms/organizationForm'
import { Form, Button, Modal, message } from 'antd'
import store from '../../store'

class Home extends PureComponent {

    formRef = React.createRef()
    constructor(props) {
        super(props)
        this.state = {
            modal2Visible: false,
            editStatus: false,
            orgList: [],
            current: 1,
            pageSize: 10,
            total: 0,
            cursel: {},
        }
        // this.props.changeMenuSelect('main')
    }

    componentDidMount() {
        this.props.connectWebsocket()
		setInterval(() => { this.props.sendData({ data: 'heart' }) }, 1000)
        this.showOderList({ current: 1, pageSize: this.state.pageSize })

        this.listener = store.subscribe(() => {
        	let web = store.getState().get('web_status')
			let select = store.getState().get('select_who')
            if (Object.keys(web).length > 0 && select == 'web') {
				if (this.state.orgList) {
                    let org = JSON.parse(JSON.stringify(this.state.orgList))
                    let tmp = org.map(s => {
                        if (s.account == web.account) {
                            s.join = web.join
                        }
                        return s
                    })
                    this.setState({ orgList: tmp })
                }
			}
		})
        this.props.changeMenuSelect('main')
		//this.props.history.push('/')
    }

    componentWillUnmount() {
        this.listener()
    }

    recFinish = (value, op) => {
        if (op == 'edit') {
            this.setState({ modal2Visible: true, editStatus: true, cursel: value })
        } else if (op == 'refresh') {
            this.$axios.get('/organization/refresh/' + value.id).then(
                res => {
                    if (res.code == 0) {
                        message.success('刷新成功')
                    } else {
                        message.error('刷新失败')
                    }
                }).catch(err => message.error('刷新失败'))
        } else {
            //发送删除
            this.$axios.get('/organization/delete/' + value.id).then(
                res => {
                    if (res.code == 0) {
                        message.success('删除成功')
                        window.location.reload()
                    } else {
                        message.error('删除失败')
                    }
                }).catch(err => message.error('删除失败'))
        }
    }

    showOderList = async obj => {
        // let result = await this.$axios.get('/organization/list', { params: { current: parseInt(obj.current) - 1, pageSize: obj.pageSize } })
        let result = await this.$axios.get('/organization/all')
        console.log('res', result)
        if (result.code == 0) {
            // let res = result.data
            let resData = result.data
            resData.forEach(item => item.key = item.id)
            this.setState({ orgList: resData || [], total: result.total || 0 })
            this.props.saveOrgList(resData)
        }
    }
    testOk = () => {
        // let sbm = this.ref.formRef.current.submit
        this.ref.refFinish()
        // sbm()
    }
    emit = async (value) => {
        //console.log(value)
        let result = await this.$axios.post('/organization/save', value)
        if (result.code == 0) {
            message.success('操作成功')
            this.setState({ modal2Visible: false }, () => window.location.reload())
        } else {
            message.error('操作失败')
        }
    }
    newOrg = () => {
        // this.props.saveBannerList([])
        this.setState({ modal2Visible: true, editStatus: false, cursel: {} })
    }
    render() {
        return (
            <div className="operate" >
                <Form
                    style={{ justifyContent: 'flex-end', marginBottom: '8px' }}
                    layout={'inline'}
                    name="control-hooks"
                    hideRequiredMark={true}
                    onFinish={this.showOderList}>
                    {/* <Form.Item name='range' label="查询机构"><Input placeholder="请输入关键字查询" /></Form.Item>
                        <Form.Item><Button type="primary" onClick={() => this.searchFun()}>查询</Button></Form.Item> */}
                    <Form.Item>
                        <Button type="primary" onClick={this.newOrg}>新增机构</Button>
                    </Form.Item>
                </Form>

                <div className="radius-style shadow-style" style={{ padding: 8 }} >
                    <OrganizationTable
                        // getFun={this.showOderList.bind(this)}
                        pageSize={this.state.pageSize}
                        total={this.state.total}
                        orderlist={this.state.orgList}
                        recFinish={this.recFinish} />
                </div>

                <Modal
                    title={this.state.editStatus ? '编辑机构' : '新增机构'}
                    width={900}
                    centered
                    visible={this.state.modal2Visible}
                    okText='确定'
                    cancelText='取消'
                    onOk={this.testOk}
                    onCancel={() => { this.setState({ modal2Visible: false }) }}>
                    <OrganizationForm
                        cursel={this.state.cursel}
                        type={this.state.editStatus}
                        emit={this.emit}
                        ref={node => { this.ref = node }}
                    />
                </Modal>
            </div >
        )
    }
}

const mapStateToProps = state => {
    return {
        cursel: state.get('cursel')
    }
}

export default connect(mapStateToProps, action)(Home)