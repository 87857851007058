import React, { PureComponent } from 'react'
import { Table, Tag, Space, Popconfirm, message } from 'antd'
import { connect } from 'react-redux'
import action from '../../store/actions/action'

class OrganizationTable extends PureComponent {

    // tagStatus = [
    //     { status: 0, tag: '断开', color: '#108ee9' },
    //     { status: 1, tag: '设备', color: '#f50' },
    //     { status: 2, tag: '正常', color: '#108ee9' },
    //     { status: 3, tag: '断开', color: '#f50' }
    // ]
    orgType = [
        { type: 1, name: '幼儿园' },
        { type: 2, name: '小学' },
        { type: 3, name: '中学' },
        { type: 4, name: '培训机构' }
    ]
    columns = [
        {
            title: 'LOGO',
            dataIndex: 'logo',
            render: url => {
                return <img src={JSON.parse(url).url} width='40' />
            }
        },
        {
            title: '机构名称',
            dataIndex: 'name'
        },
        {
            title: '登陆账号',
            dataIndex: 'account',
        },
        {
            title: '邀请码',
            dataIndex: 'invite'
        },
        {
            title: '负责人',
            dataIndex: 'manager',
        },
        {
            title: '联系电话',
            dataIndex: 'phone',
        },
        {
            title: '联系地址',
            dataIndex: 'address'
        },
        {
            title: '网络状态',
            dataIndex: 'join',
            render: (tag, record) => 
                // let single = this.tagStatus.find(item => item.status == tag)
                // return <Tag color={single.color}>{single.tag}</Tag>

                <Space>
                    <Tag color={ tag == 0 ? '#f50' : tag == 1 ? '#108ee9' : tag == 2 ? '#f50' : '#108ee9' }>{ tag == 0 ? '断开' : tag == 1 ? '正常' : tag == 2 ? '断开' : '正常' }</Tag>
                    <Tag color={ tag == 0 ? '#f50' : tag == 1 ? '#f50' : tag == 2 ? '#108ee9' : '#108ee9'}>{ tag == 0 ? '断开' : tag == 1 ? '断开' : tag == 2 ? '正常' : '正常' }</Tag>
                    <Popconfirm
                        title='确认要刷新页面？'
                        okText='是'
                        cancelText='否'
                        onConfirm={() => this.onConfirm(record, 'refresh')}
                        onCancel={this.onCancel}>
                        <a href='#'>刷新</a>
                    </Popconfirm>
                </Space>
        },
        {
            title: '操作',
            key: 'action',
            render: (_text, record) => (
                <Space size="middle">
                    {<a onClick={() => { this.editTabel(record, 'edit') }}>编辑</a>}
                    {
                        <Popconfirm
                            title='确认要删除改记录？'
                            okText='是'
                            cancelText='否'
                            onConfirm={() => this.onConfirm(record, 'delete')}
                            onCancel={this.onCancel}>
                            <a href='#'>删除</a>
                        </Popconfirm>
                    }
                </Space>
            ),
        },
    ]
    constructor(props) {
        super(props)
        this.state = {
            key: '',
            select: ['']
        }
    }
    onConfirm = (record, op) => {
        this.editTabel(record, op)
    }
    onCancel() {
        message.info('取消操作')
    }

    editTabel = (value, op) => {
        this.props.recFinish(value, op)
    }

    render() {
        return (
            <Table
                columns={this.columns}
                dataSource={this.props.orderlist}
                // onChange={this.onChangeTest}
                showSizeChanger
                showQuickJumper
                pagination={{
                    pageSize: this.props.pageSize,
                    total: this.props.total,
                    hideOnSinglePage: true,
                    showSizeChanger: true,
                    showQuickJumper: true
                }}
            />
        )
    }
}

const mapStateToProps = state => {
    return {}
}
export default connect(mapStateToProps, action, null, { forwardRef: true })(OrganizationTable)