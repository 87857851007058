export const CHANGE_MENU_SELECT = 'CHANGE_MENU_SELECT'
export const CONNECT_WEBSOCKET = 'CONNECT_WEBSOCKET'
export const DISCONNECT_WEBSOCKET = 'DISCONNECT_WEBSOCKET'
export const SEND_WEBSOCKET = 'SEND_WEBSOCKET'
export const SET_LOGIN_INFO = 'SET_LOGIN_INFO'
export const SAVE_ORGANIZATION = 'SAVE_ORGANIZATION'
export const ORG_LIST = 'ORG_LIST'
export const BANNER_LIST = 'BANNER_LIST'
export const DEVICE_RF = 'DEVICE_RF'
export const LIVE_ORDER = 'LIVE_ORDER'
export const TURN_LEAVE = 'TURN_LEAVE'
export const CALL_TEACHER = 'CALL_TEACHER'
export const CLEAR = 'CLEAR'
export const SELECT_WHO = 'SELECT_WHO'
export const SCREEN_CHANGE = 'SCREEN_CHANGE'
export const SOUND = 'SOUND'
export const MESSAGE = 'MESSAGE'
export const WEB_STATUS = 'WEB_STATUS'
export const ERROR_CN = 'ERROR_CN'
export const CHECK_VIDEO = 'CHECK_VIDEO'
export const VISITOR = 'VISITOR'