import React, { PureComponent } from 'react'
import { Table, Modal } from 'antd'
import { connect } from 'react-redux'
import action from '../../store/actions/action'
import TextArea from 'rc-textarea'

class AlarmTable extends PureComponent {

    colors = ["magenta", "red", "volcano", "orange", "gold", "lime", "green", "cyan", "blue", "geekblue", "purple"]

    columns = [
        {
            title: '报警教室',
            dataIndex: 'name'
        },
        {
            title: '报警时间',
            dataIndex: 'time'
        },
        {
            title: '处理结果',
            dataIndex: 'info'
        },
        {
            title: '操作',
            key: 'action',
            render: (_text, record) => (
                <a onClick={() => this.auditFun(record)}>{ record.status == '0' ? '去处理' : '已处理'}</a>
            ),
        },
    ]
    constructor(props) {
        super(props)
        this.state = {
            showModal: false,
            editStatus: '0',
            value: '',
            record: {}
        }
    }

    auditFun = (record) => {
        // this.props.emit(record.id)
        this.setState({showModal:true, record, editStatus: record.status, value: record.status == '1' ? record.info : ''})
    }
    onChange = ({ target: { value } }) => {
        this.setState({ value });
    }
    onOk = () => {
        let param = {
            id: this.state.record.id,
            account: this.state.record.account,
            name: this.state.record.name,
            time: this.state.record.time,
            info: this.state.value,
            status: '1'
        }
        this.props.emit(param)
        this.setState({showModal: false})
    }
    pageChange = page => {
        this.props.pageChange(page)
    }

    render() {
        return (
            <>
                <Table
                    columns={this.columns}
                    dataSource={this.props.orderlist}
                    showSizeChanger
                    showQuickJumper
                    onChange={this.pageChange}
                    pagination={{
                        pageSize: this.props.pageSize,
                        total: this.props.total,
                        // hideOnSinglePage: true,
                        showSizeChanger: true,
                        showQuickJumper: true,
                        pageSizeOptions: [5, 10]
                    }}
                />
                <Modal 
					title={this.state.editStatus == '0' ? '输入意见' : '查看意见'} 
					centered 
					visible={this.state.showModal} 
                    onOk={ () => this.onOk() }
					onCancel={ () => { this.setState({ showModal: false }) }}>
					<TextArea
                        value={this.state.value}
                        onChange={this.onChange}
                        placeholder="请输入您的处理意见..."
                        style={{width: '100%'}}
                        autoSize={{ minRows: 3, maxRows: 5 }}/>
				</Modal>
            </>
        )
    }
}

const mapStateToProps = state => {
    return {
        // betonlist: state.get('betonlist')
    }
}
export default connect(mapStateToProps, action, null, { forwardRef: true })(AlarmTable)