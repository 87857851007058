import React from 'react'
// import 'antd/dist/antd.css'
import './index.css'
import { Form, Input, Layout, Menu, Popover, Modal, message } from 'antd'
import { MenuUnfoldOutlined, MenuFoldOutlined, UserOutlined, ReconciliationOutlined, VideoCameraOutlined, UploadOutlined, TeamOutlined, SolutionOutlined, BorderOuterOutlined, IdcardOutlined } from '@ant-design/icons'
import { connect } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'
import action from '../../store/actions/action'
import adminImg from '../../assets/img/admin.jpg'
const { Header, Sider, Content } = Layout

const layout = { labelCol: { span: 7 }, wrapperCol: { offset: 3, span: 8 } }

class MainPage extends React.Component {

    formRef = React.createRef()
    constructor(props) {
        super(props)
        this.state = {
            collapsed: false,
            passModal: false
        }
        if (window.localStorage.getItem('auto-login') !== 'yes' && window.sessionStorage.getItem('auto-login') !== 'yes') {
            this.props.history.push('/login')
        } else {
            window.localStorage.getItem('login-info') && (this.loginInfo = JSON.parse(window.localStorage.getItem('login-info')))
            window.sessionStorage.getItem('login-info') && (this.loginInfo = JSON.parse(window.sessionStorage.getItem('login-info')))
            this.role = this.loginInfo.type
            this.props.setLoginInfo(this.loginInfo)
            if (this.role == 1) {
                // 超级管理员
                this.props.changeMenuSelect('main')
            } else if (this.role == 2) {
                // 机构管理员
                this.props.changeMenuSelect('student')
                this.props.history.push('/student')
            } else if (this.role == 3) {
                // 老师
            } else if (this.role == 4) {
                // 家长
            } else {
                this.props.history.push('/login')
            }
        }
    }

    componentDidMount() {
        let info = this.props.location
        console.log('main', info)
		//info.pathname.indexOf('/student')
        this.props.connectWebsocket()
        setInterval(() => { this.props.sendData('heart') }, 10000)
    }

    content = (
        <div>
            <div onClick={() => this.setting(this)}><a>设置</a></div>
            <hr />
            <div><a onClick={() => this.logout(this)}>登出</a></div>
        </div>
    )
    state = {
        collapsed: false,
    }
    orgImg = () => {
        if (this.props.organization && this.props.organization.logo)
            return JSON.parse(this.props.organization.logo)['url']
        else
            return ''
    }
    orgName = () => {
        if (this.props.organization)
            return this.props.organization.name
        else
            return ''
    }
    setting = (_this) => {
        _this.setState({ passModal: !_this.state.passModal })
    }
    logout = (_this) => {
        window.sessionStorage.getItem('auto-login') && window.sessionStorage.removeItem('auto-login')
        window.sessionStorage.getItem('login-info') && window.sessionStorage.removeItem('login-info')

        window.localStorage.getItem('auto-login') && window.localStorage.removeItem('auto-login')
        window.localStorage.getItem('login-info') && window.localStorage.removeItem('login-info')
        _this.props.history.push('/login')
    }
    toggle = () => {
        this.setState({ collapsed: !this.state.collapsed })
    }
    oKpass = () => {
        let sbm = this.formRef.current.submit
        sbm()
    }
    onPassFinish = async value => {
        let mod = false
        if (value.new == value.confirm) {
            if (value.old == this.loginInfo.password) {
                mod = true
            }
        }
        if (mod) {
            let oldpass = this.loginInfo.password
            this.loginInfo.password = value.new
            let result = await this.$axios.post('/admin/password', this.loginInfo)
            if (result.code == 0) {
                message.success('修改密码成功')
                this.setState({ passModal: false })
                this.formRef.current.resetFields()
                window.sessionStorage.getItem('login-info') && window.sessionStorage.setItem('login-info', JSON.stringify(this.loginInfo))
                window.localStorage.getItem('login-info') && window.localStorage.setItem('login-info', JSON.stringify(this.loginInfo))
            } else {
                this.loginInfo.password = oldpass
                message.error('修改密码失败')
            }
        } else {
            message.error('修改密码失败')
        }
    }
    render() {
        return (
            <Layout style={{ height: '100vh' }}>
                <Sider trigger={null} collapsible collapsed={this.state.collapsed}>
                    <div className="logo">
                        {/* <SoftwareLogo width={'30px'} height={'30px'} color={'rgb(38, 132, 255)'} /> */}
                        {!this.state.collapsed ? <div>安全接送管理系统</div> : null}
                    </div>
                    <Menu theme="dark" mode="inline" selectedKeys={this.props.cursel}>
                        {this.role == 1 ? <Menu.Item key="main" icon={<UserOutlined />}>学校管理<Link to='/'></Link></Menu.Item> : ''}
                        {this.role == 1 ? <Menu.Item key="usual" icon={<ReconciliationOutlined />}>通用设置<Link to='/usual'></Link></Menu.Item> : ''}
                        {this.role == 2 ? <Menu.Item key="student" icon={<UploadOutlined />}>学生管理<Link to='/student'></Link></Menu.Item> : ''}
                        {this.role == 2 ? <Menu.Item key="teacher" icon={<UserOutlined />}>老师管理<Link to='/teacher'></Link></Menu.Item> : ''}
                        {this.role == 2 ? <Menu.Item key="alarm" icon={<VideoCameraOutlined />}>报警管理<Link to='/alarm'></Link></Menu.Item> : ''}
                        {this.role == 2 || this.role == 3 ? <Menu.Item key="leave" icon={<BorderOuterOutlined />}>大屏管理<Link to='/leave'></Link></Menu.Item> : ''}
                        {this.role == 2 ? <Menu.Item key="interest" icon={<IdcardOutlined />}>兴趣班管理<Link to='interest'></Link></Menu.Item> : '' }
                        {this.role == 2 ? <Menu.Item key="visitor" icon={<SolutionOutlined />}>来访管理<Link to='visitor'></Link></Menu.Item> : '' }
                        {this.role == 2 ? <Menu.Item key="settings" icon={<TeamOutlined />}>系统设置<Link to='/settings'></Link></Menu.Item> : ''} 
                        {/* {this.role == 2 ? <Menu.Item key="check" icon={<VideoCameraOutlined />}>考勤管理<Link to='/check'></Link></Menu.Item> : ''} 
                        
                        <SubMenu key="sub2" icon={<TeamOutlined />} title="备用二级菜单">
                            <Menu.Item key="6">备用一</Menu.Item>
                            <Menu.Item key="8">备用二</Menu.Item>
                        </SubMenu> */}
                    </Menu>
                </Sider>
                <Layout className="site-layout">
                    <Header className="site-layout-background" style={{ padding: 0, display: 'flex', justifyContent: 'space-between' }}>
                        {React.createElement(this.state.collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, { className: 'trigger', onClick: this.toggle })}
                        <div style={{ alignItems: 'center', justifyContent: 'center', color: 'rgb(28 121 52)', fontSize: '16px' }}>
                            安全接送管理系统
                        </div>
                        <div style={{ paddingRight: '16px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                            <Popover content={ this.content }>
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', cursor: 'pointer' }}>
                                    <img src={this.role == 1 ? adminImg : this.orgImg()} style={{ width: '32px', height: '32px', borderRadius: '50%', marginRight: '16px' }} />
                                    <span>{this.role == 1 ? '管理员' : this.orgName()}</span>
                                </div>
                            </Popover>
                        </div>
                    </Header>
                    <Content className="site-layout-background" style={{ margin: '24px 16px', padding: 24, minHeight: 280 }}>
                        {this.props.children}
                    </Content>
                </Layout>
                <Modal visible={this.state.passModal} centered okText='修改' title={"修改密码"} onOk={this.oKpass} onCancel={() => { this.setState({ passModal: !this.state.passModal }) }}>
                    <Form {...layout} ref={this.formRef} name="control-pass" hideRequiredMark={true} onFinish={this.onPassFinish}>
                        <Form.Item name="old" label="输入原密码" rules={[{ required: true, message: '原密码不能为空' }]}>
                            <Input type="password" />
                        </Form.Item>
                        <Form.Item name="new" label="输入新密码" rules={[ { required: true, message: '新密码不能为空' }]}>
                            <Input type="password" />
                        </Form.Item>
                        <Form.Item name="confirm" label="确认新密码" rules={[{ required: true, message: '再次确认新密码' }]}>
                            <Input type="password" />
                        </Form.Item>
                    </Form>
                </Modal>
            </Layout>
        )
    }
}

const mapStateToProps = state => {
    return {
        cursel: state.get('cursel'),
        organization: state.get('organization')
    }
}

export default connect(mapStateToProps, action)(withRouter(MainPage))