export default class TimeUtils {
    static formatDate = value => {
        let date = new Date(value)
        let YY = date.getFullYear() + '-'
        let MM = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-'
        let DD = (date.getDate() < 10 ? '0' + (date.getDate()) : date.getDate())
        let hh = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':'
        let mm = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':'
        let ss = (date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds())
        return YY + MM + DD + ' ' + hh + mm + ss
    }
    static getWeek = value => {
        let date = new Date(value)
        let wk = '星期' + '日一二三四五六'.charAt(date.getDay())
        return wk
    }
    static formatStamp = value => {
        let str = value.replace(/-/g, '/')
        let date = new Date(str)
        return date.getTime()
    }
    static getTime = () => {
        let date = new Date()
        
        let hh = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':'
        let mm = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':'
        let ss = (date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds())
        return hh + mm + ss
    }
}