import React, { PureComponent } from 'react'
import { Table, Space, Popconfirm } from 'antd'
import { connect } from 'react-redux'
import action from '../../store/actions/action'
import avatatImg from '../../assets/img/avatar.png'

class StudentTable extends PureComponent {

    columns = [
        {
            title: '头像',
            dataIndex: 'avatar',
            render: url => {
                return <img src={url || avatatImg} width='40' height='40'/>
            }
        },
        {
            title: '学生姓名',
            dataIndex: 'name',
            // render: (name, record) => <a>{name}</a>,
        },
        {
            title: '学号',
            dataIndex: 'num'
        },
        {
            title: '手机号',
            dataIndex: 'phone'
        },
        {
            title: '操作',
            key: 'action',
            render: (_text, record) => (
                <Popconfirm
                    title='确认要删除改记录？'
                    okText='是'
                    cancelText='否'
                    onConfirm={() => this.deletePerson(record)}
                    onCancel={this.onCancel}>
                    <a href='#'>删除</a>
                </Popconfirm>
            ),
        },
    ]
    constructor(props) {
        super(props)
        this.state = {
            orgList: []
        }
    }
    componentDidMount() {
    }
    testSendMessage = async (tag, behavior) => {
        let result = await this.$axios.post('/pushUniform', { code: tag, devid: this.state.cursel, orgid: this.props.organization.id, behavior: behavior })
    }
    deletePerson = record => {
        this.props.emit(record.id)
    }
    onChange = (page, pageSize) => {
        console.log('page index', page, pageSize)
        this.setState({pageSize: page.pageSize})
        this.props.pageChange(page)
    }
    
    render() {
        return (
            <>
                <Table
                    columns={this.columns}
                    dataSource={this.props.orderlist}
                    showSizeChanger
                    showQuickJumper
                    onChange={this.onChange}
                    pagination={{
                        pageSize: this.props.pageSize,
                        total: this.props.total,
                        // hideOnSinglePage: true,
                        showSizeChanger: true,
                        showQuickJumper: true
                    }}
                />
            </>
        )
    }
}

const mapStateToProps = state => {
    return {
        organization: state.get('organization')
    }
}
export default connect(mapStateToProps, action, null, { forwardRef: true })(StudentTable)