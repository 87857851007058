import React from 'react'
import { connect } from 'react-redux'
import action from '../../store/actions/action'
import { Form, Select, message, Button, Input } from 'antd'
import TeacherTable from '../../components/tables/TeacherTable'

const { Option } = Select

class Teacher extends React.Component {
	
	formRef = React.createRef()
	constructor(props) {
		super(props)

		this.state = {
			isVisible: false,
			type: 'create',
			item: '',
			who: 'beton',
			gradelist: ["一年级", "二年级", "三年级", "四年级", "五年级", "六年级" ],
			partlist: [],
			showlist: [],
			modal2Visible: false,
			cursel: -1,
			curstu: '',
			TeacherList: [],
			grade: [],
			curPage: 1,
			pageSize: 10,
			total: 0
		}
		// this.props.changeMenuSelect('teacher')
	}
	componentDidMount = async () => {
		if (Object.keys(this.props.organization).length == 0) {
			let orgSave = window.localStorage.getItem('organization')
			if (orgSave) {
				this.props.saveOrganization(JSON.parse(orgSave))
			}
		}
		this.props.changeMenuSelect('teacher')
		this.props.history.push('/teacher')

		let res = await this.$axios.get('/grade/list/' + this.props.organization.account)
        if (res.code == 0) {
            this.setState({grade: JSON.parse(res.data.grade)})
        }
	}
	
	searchFun = async () => {
		console.log(this.props.organization)
        let params = {
            account: this.props.organization.account,
			curPage: 1,
			pageSize: this.state.pageSize
        }
        
        let range = this.formRef.current.getFieldValue('range')
        if (range && range.trim().length > 0) {
            params.range = range
        }
        if (parseInt(this.state.cursel) >= 0) {
            params.grade = this.state.cursel
        }
        if (parseInt(this.state.curstu) >= 0) {
            params.clazz = this.state.curstu
        }
        let res = await this.$axios.post('/person/find/teacher', params)
        if (res.code == 0) {
            this.setState({ TeacherList: res.data.content, total: res.data.totalElements })
        }
	}
	emit = async (rec, type) => {
        if (type == 'del') {
			let res = await this.$axios.get('/person/delete/' + rec.id)
			if (res.code == 0) {
				this.searchFun()
			} else {
				message.error("删除失败！")
			}

        } else if (type == 'aud') {
            let res = await this.$axios.get('/person/audit/' + rec.id)
			if (res.code == 0) {
				let newStu = this.state.TeacherList.map(it => {
					if (it.id == rec.id) {
						it.audit = res.data.audit
					}
					return it
				})
				this.setState({TeacherList: newStu})
			} else {
				message.error("设置失败！")
			}

        } else if (type == 'man') {
			console.log(rec, type, 'shezhi')
			let res = await this.$axios.post('/person/manager', rec)
			if (res.code == 0) {
				let newStu = this.state.TeacherList.map(it => {
					if (it.openid == rec.openid) {
						it.manager = res.data.result
					}
					return it
				})
				this.setState({TeacherList: newStu})
			} else {
				message.error("设置失败！")
			}
        }
	}
	pageChange = async page => {
		this.setState({ curPage: page.current, pageSize: page.pageSize })
		let params = {
			account: this.props.organization.account,
			curPage: page.current,
			pageSize: page.pageSize
		}
		let range = this.formRef.current.getFieldValue('range')
        if (range && range.trim().length > 0) {
            params.range = range
        }
        if (parseInt(this.state.cursel) >= 0) {
            params.grade = this.state.cursel
        }
        if (parseInt(this.state.curstu) >= 0) {
            params.clazz = this.state.curstu
        }
		let res = await this.$axios.post('/person/find/teacher', params)
		if (res.code == 0) {
			this.setState({ TeacherList: res.data.content, total: res.data.totalElements })
		}
	}

	gradeChange = value => {
		
		this.setState({ cursel: value, curstu: -1 }, () => {
			console.log('gradeChange', value)
			this.formRef.current.setFieldsValue({clazz: []})
		})
	}

	render() {
		return (
			<div style={{ display: 'flex', flexDirection: 'column', height: '100%', width: '100%' }}>
				<Form
					style={{ justifyContent: 'flex-end', marginBottom: '8px' }}
					layout={'inline'}
					ref={this.formRef}
					name="control-hooks"
					hideRequiredMark={true}
					onFinish={this.showOderList}>
					<Form.Item name='grade' label="年级" dataSource={this.state.gradelist}>
						<Select
							style={{ width: 120, marginRight: 16 }}
							placeholder="选择年级"
							allowClear
							onChange={value => this.gradeChange(value)}>
							{
								this.state.gradelist.map((item, index) => <Option key={index} value={index}>{item}</Option>)
							}
						</Select>
					</Form.Item>

					<Form.Item name='clazz' label="班级">
						<Select style={{ width: 120 }}
							placeholder="选择班级"
							allowClear
							onChange={value => { this.setState({ curstu: value }) }}>
							{
							// this.state.grade.map((item, index) => <Option key={index} value={index}>{item}班</Option>)
								Array.from({length: this.state.grade[this.state.cursel]}).map((item, index) => <Option key={index} value={index}>{index+1}班</Option>)
							}
						</Select>
					</Form.Item>

					<Form.Item name='range' label="老师姓名">
						<Input placeholder="请输入关键字查询" />
					</Form.Item>

					<Form.Item>
						<Button type="primary" onClick={() => this.searchFun()}>查询</Button>
					</Form.Item>
				</Form>

				<div className="radius-style shadow-style" style={{ padding: 8 }} >
					<TeacherTable
						emit={this.emit}
						pageSize={this.state.pageSize}
						pageChange={this.pageChange}
						total={this.state.total}
						orderlist={this.state.TeacherList}
						recFinish={this.recFinish} />
				</div>
			</div >
		)
	}
}

const mapStateToProps = (state) => {
	return {
		cursel: state.get('cursel'),
		organization: state.get('organization')
	}
}
export default connect(mapStateToProps, action)(Teacher)