import React, { useImperativeHandle, useEffect, useState } from 'react'
import { Form, Input, Select, Row, Col, Switch, Button } from 'antd'
import TextArea from 'antd/lib/input/TextArea'
import { connect } from 'react-redux'
import action from '../../store/actions/action'
import Modal from 'antd/lib/modal/Modal'
import MyUpload from './myupload'

const layout = { labelCol: { span: 7 }, wrapperCol: { offset: 2, span: 12 } }
/* eslint-disable */
const OrganizationForm = React.forwardRef((props, ref) => {

    const [logoFileList, setLogoFileList] = props.type ? useState([JSON.parse(props.cursel.logo)]) : useState([])
    const [lock, setLock] = useState(props.type ? (props.cursel.status === 1 ? true : false) : true)

    const [form] = Form.useForm()

    useEffect(() => {
        if (props.type) {
            console.log(props.cursel, 'useEffect1', props.type)
            form.setFieldsValue({ id: props.cursel.id })
            form.setFieldsValue({ account: props.cursel.account })
            form.setFieldsValue({ info: props.cursel.info })
            form.setFieldsValue({ address: props.cursel.address })
            form.setFieldsValue({ invite: props.cursel.invite })
            form.setFieldsValue({ manager: props.cursel.manager })
            form.setFieldsValue({ name: props.cursel.name })
            form.setFieldsValue({ password: props.cursel.password })
            form.setFieldsValue({ phone: props.cursel.phone })
            form.setFieldsValue({ status: props.cursel.status })
            form.setFieldsValue({ logo: props.cursel.logo })
        } else {
            form.resetFields()
        }
    }, props.cursel.id)

    const emit = value => {
        console.log('emit', value)
        setLogoFileList(value)
    }
    const onFinish = () => {
        let defaultImg = {
            name: "default.png",
            uid: "rc-upload-1627406436564-2",
            url: "https://jiesong.sanlongedu.com/uploads/common/default.png"
        }
        let tmpList = [], tmpString = ''
        props.bannerList.map(item => tmpList.push(JSON.stringify(item)))
        tmpString = tmpList.join(',')
        let params = {
            name: form.getFieldValue('name'),
            account: form.getFieldValue('account'),
            manager: form.getFieldValue('manager'),
            phone: form.getFieldValue('phone'),
            address: form.getFieldValue('address'),
            password: props.cursel.password,
            invite: props.cursel.invite,
            logo: JSON.stringify(logoFileList[0] || defaultImg),
            info: form.getFieldValue('info') || '',
            status: lock ? 1 : 0,
        }
        props.type && (params['id'] = props.cursel.id)
        props.emit(params)
    }
    useImperativeHandle(ref, () => ({
        resetFields: () => {
            form.resetFields()
        },
        refFinish: () => form.submit()
    }))
    return (
        <>
            <Form {...layout} form={form} preserve={false} name="control-hooks" requiredMark={true} onFinish={onFinish}>
                <Row>
                    <Col span={12} key={1}>
                        <Form.Item name="name" label="学校名称" rules={[{ required: true, message: '机构名称不能为空' }]}><Input /></Form.Item>
                        <Form.Item name="account" label="登录账号" rules={[{ required: true, message: '登录账号不能为空' }]}><Input /></Form.Item>
                        <Form.Item name="manager" label="负责人"><Input /></Form.Item>
                        <Form.Item name="phone" label="联系电话"><Input /></Form.Item>
                        <Form.Item name="address" label="地址"><Input  /></Form.Item>
                        <Form.Item name="logo" label="学校Logo"><MyUpload emit={emit} listType={'picture'} fileList={logoFileList} /></Form.Item>
                        <Form.Item name="status" label="启停用"><Switch checked={lock} onChange={() => setLock(!lock)} /></Form.Item>
                    </Col>
                    <Col span={12} key={2}>
                        <Form.Item name="info" label="学校简介">
                            <TextArea name="memo" placeholder="请输入学校简介......" autoSize={{ minRows: 5 }} />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </>
    )
})

const mapStateToProps = state => {
    return {
        bannerList: state.get('bannerList')
    }
}
export default connect(mapStateToProps, action, null, { forwardRef: true })(OrganizationForm)