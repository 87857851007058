import * as types from '../types/type'

export default {
  setLoginInfo(loginInfo) { return { type: types.SET_LOGIN_INFO, payload: loginInfo} },
  changeMenuSelect(cursel) { return { type: types.CHANGE_MENU_SELECT, payload: cursel } },
  connectWebsocket() { return { type: types.CONNECT_WEBSOCKET } },
  // 断开连接,你可以在退出登录时调用,或者时你离开你的模块时调用,都可以,看你自己的情况决定
  disconnect() { return { type: types.DISCONNECT_WEBSOCKET } },
  sendData(data) { return { type: types.SEND_WEBSOCKET, data } },
  saveOrganization(organization) { return { type: types.SAVE_ORGANIZATION, payload: organization } },
  saveOrgList(orgList) { return { type: types.ORG_LIST, payload: orgList} },
  saveBannerList(bannerList) { return { type: types.BANNER_LIST, payload: bannerList} },
}