import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import store from './store'
import Jump from './jump'
import { Provider } from 'react-redux'
import axios from './axios'
import zhCN from 'antd/lib/locale/zh_CN'
import { ConfigProvider } from 'antd'

React.$axios = axios

const App = (
    <Provider store={ store }>
        <ConfigProvider locale={ zhCN }>
            <Jump />
        </ConfigProvider>
    </Provider >
)

ReactDOM.render(App, document.getElementById('root'))