import { Upload, Button, message } from 'antd'
import { UploadOutlined } from '@ant-design/icons'
import React, { useState } from 'react'
import { connect } from 'react-redux'
import action from '../../store/actions/action'

const MyUpload = React.forwardRef((props, ref) => {

    const [fileList, setFileList] = useState(props.fileList)

    const onChange = info => {
        
        if (info.file.status === 'removed') {
            setFileList([])
            emit([])
        } else {
            if (info.fileList) {
                
                let fileList = [...info.fileList]
                console.log(fileList, 'info')
                fileList = fileList.map(file => {
                    if (file.response) file.url = file.response.data.normal
                    return file
                })
                let setFile = fileList[fileList.length - 1]
                let getFile = [{
                    uid: setFile.uid || '',
                    name: setFile.name || '',
                    url: setFile.url || ''
                }]
                setFileList(getFile)
            }

            if (info.file.status === 'done') {
                let getFile = [{
                    uid: info.file.uid || '',
                    name: info.file.name || '',
                    url: info.file.url || ''
                }]
                emit(getFile)
            }
        }

    }
    const emit = file => props.emit(file)
    const beforeUpload = file => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'
        if (!isJpgOrPng) {
            message.error('你只能上传jpg和png格式的图片!')
        }
        const isLt2M = file.size / 1024 / 1024 < 2
        if (!isLt2M) {
            message.error('文件大小不能超过 2MB!')
        }
        return isJpgOrPng && isLt2M
    }
    //text, picture 和 picture-card
    return (
        <Upload
            action='https://jiesong.sanlongedu.com/api/fileUpload?type=logo'
            listType={props.listType}
            fileList={fileList}
            maxCount={1}
            onChange={(fileList) => onChange(fileList)}
            beforeUpload={beforeUpload}>
            <Button>
                <UploadOutlined />上传文件
            </Button>
        </Upload>
    )

})

const mapStateToProps = state => {
    return {}
}
export default connect(mapStateToProps, action, null, { forwardRef: true })(MyUpload)