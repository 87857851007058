import React, { PureComponent } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import action from '../../store/actions/action'
import { Form, Input, Button, Select, Card } from 'antd'
import noRepeat from '../../utils/tool'
import store from '../../store'
import './index.css'
import avatatImg from '../../assets/img/avatar.png'
import backImg from '../../assets/img/talk-bg.svg'

const { Option } = Select
const layout = { labelCol: { span: 6 }, wrapperCol: { offset: 2, span: 12 } }
const tailLayout = { wrapperCol: { offset: 8, span: 12 } }

class Talk extends PureComponent {

    formRef = React.createRef()
    constructor(props) {
        super(props)
        this.state = {
            isLogin: false,
            gradelist: ["一年级", "二年级", "三年级", "四年级", "五年级", "六年级"],
            curstu: 0,
            grade: [],
            value: '',
            student: [],
            loginInfo: { user: '', grade: '0', clazz: '0' },
            organizations: [],
            grades: [],
            saveOrg: '',
            voice: ''
        }
    }

    componentDidMount = async () => {
        /*window.addEventListener('resize', () => {
            let s = Math.floor((window.innerWidth-36)/146)
            console.log(this.state.student.length % s)
        })*/
        let organizations = await this.$axios.get('/organization/all')
        if (organizations.code == 0) {
            this.setState({ organizations: organizations.data })
        }
        let grades = await this.$axios.get('/grade/list')
        if (grades.code == 0) {
            let tmp = grades.data.map(it => {
                it.grade = JSON.parse(it.grade)
                return it
            })
            this.setState({ grades: tmp })
        }

        let enLogin = window.localStorage.getItem('leave-login')
        let LoginInfo = window.localStorage.getItem('leave-info')
        let saveOrg = window.localStorage.getItem('leave-name')
        if (enLogin == '1' && LoginInfo) {
            let jsonLog = JSON.parse(LoginInfo)
            let res = await this.$axios.post('/person/find/allStudent', jsonLog)
            if (res.code == 0) {
                let studentList = noRepeat(res.data)
                // let studentList = res.data
                saveOrg = saveOrg + this.state.gradelist[parseInt(jsonLog.grade)] + '（' + (parseInt(jsonLog.clazz) + 1) + '）班'
                setTimeout(() => {
                    this.setState({ student: studentList, isLogin: true, loginInfo: jsonLog, saveOrg: saveOrg })
                }, 0)
            }
        }

        this.props.connectWebsocket()
        setInterval(() => { this.props.sendData('heart') }, 10000)

        this.listener = store.subscribe(() => {
            let call = store.getState().get('call_teacher')
            let clear = store.getState().get('clear')
            let sound = store.getState().get('sound')
            let select = store.getState().get('select_who')
            console.log('state sound', call, clear, sound, select)
            if (Object.keys(call).length > 0 && select == 'call') {
                if (call.account == this.state.loginInfo.user && call.grade == this.state.loginInfo.grade && call.level == this.state.loginInfo.clazz) {
                    let tmp = this.state.student.map(it => {
                        if (it.num == call.num) {
                            it.status = call.status
                            it.time = call.time
                            it.job = call.job
                            it.avatar = call.avatar
                            it.realTime = call.realTime
                        }
                        console.log(it, 'sig')
                        return it
                    })
                    this.setState({ student: tmp })
                }
            }
            if (Object.keys(clear).length > 0 && select == 'clear') {
                console.log('state clear', clear)
                if (clear.account == this.state.loginInfo.user) {
                    let tmp = this.state.student.map(it => {
                        it.status = '0'
                        return it
                    })
                    this.setState({ student: tmp })
                }
            }

            if (Object.keys(sound).length > 0 && select == 'sound') {

                if (sound.account == this.state.loginInfo.user && sound.grade == this.state.loginInfo.grade && sound.level == this.state.loginInfo.clazz) {
                    if (this.state.voice != sound.sound) {
                        this.setState({ voice: sound.sound })
                    }
                }
            }
        })

        setInterval(() => {

            if (this.state.student.length > 0) {
                let tmp = JSON.parse(JSON.stringify(this.state.student))

                let d = tmp.map(it => {
                    // let c = ((Number(it.time) - Number(new Date().getTime())) / 60000).toFixed(0)
                    // if (c > 0) {
                    //     it.realTime = c
                    // } else {
                    //     it.realTime = '0'
                    // }
                    // return it

                    let diff = Number(it.time) - Number(new Date().getTime())
                    let c = (diff / 60000).toFixed(0)
                    if (c > 0) {
                        it.realTime = c
                    } else {
                        if (diff <= 0)
                            it.realTime = '0'
                        else
                            it.realTime = '1'
                    }
                    return it
                })


                this.setState({ student: d })
            }
        }, 1000)
        /*
        // let grand = ['爸爸', '妈妈', '爷爷', '奶奶', '外公', '外婆', '其他']
        let tmp = []
        for (let i = 0; i < this.state.student.length; i++) {
            let stu = JSON.parse(JSON.stringify(this.state.student[i]))
            stu.status = Math.round(Math.random()*5)
            stu.realTime = Math.round(Math.random()*50)
            // let rand = Math.round(Math.random()*(grand.length-1))
            // stu.num = i+1
            // stu.job = grand[rand]
            tmp.push(stu)
        }
        this.setState({ student: tmp })*/
    }
    exitLogin = () => {
        store.dispatch({ type: 'SELECT_WHO', payload: '' })
        this.setState({ isLogin: false, voice: '' })
    }

    componentWillUnmount() {
        this.listener()
        this.props.disconnect()
    }
    onChange = e => {
        const { value } = e.target;
        this.setState({ value })
    }

    onBlur = async () => {
        this.setState({ grade: [] })
        if (this.state.value.trim() != '') {
            this.state.organizations.find(it => {
                if (it.account == this.state.value.trim()) {
                    this.state.grades.find(s => {
                        if (s.orgid == it.account) {
                            this.setState({ grade: s.grade, saveOrg: it.name })
                        }
                    })
                }
            })
        }
    }

    gradeChange = value => {
        this.setState({ cursel: value, curstu: -1 }, () => {
            this.formRef.current.setFieldsValue({ clazz: [] })
        })
    }
    onFinish = async values => {
        if (this.state.value.trim() != '') {
            let res = await this.$axios.post('/person/find/allStudent', values)
            if (res.code == 0) {
                let studentList = noRepeat(res.data)
                this.setState({ student: studentList, isLogin: true, loginInfo: values })
                window.localStorage.setItem('leave-login', '1')
                window.localStorage.setItem('leave-info', JSON.stringify(values))
                window.localStorage.setItem('leave-name', this.state.saveOrg)
                let saveOrg = this.state.saveOrg + this.state.gradelist[parseInt(values.grade)] + '（' + (parseInt(values.clazz) + 1) + '）班'
                this.setState({ saveOrg: saveOrg })
            }
        }
    }

    render() {
        return (
            <div style={{ height: '100%', backgroundImage: `url(${backImg})` }}>
                <div style={{ height: !this.state.isLogin ? '100%' : 'auto' }}>
                    {
                        this.state.isLogin
                            ?
                            <div className="page-container">
                                <div className="container">
                                    <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between', alignItems: 'center', borderRadius: '35px', backgroundColor: 'rgba(41, 143, 255, 1)' }}>
                                        <div style={{ width: '170px' }} />
                                        <span className="talk-title">{this.state.saveOrg}</span>
                                        <div className="talk-exit">
                                            <div style={{ display: 'flex', width: '74px', justifyContent: 'space-between' }}>
                                                <img src={require('../../assets/img/talk-exit.svg')} />
                                                <a className="talk-exit-text" onClick={this.exitLogin}>退出</a>
                                            </div>
                                        </div>
                                        {/* <audio controls ref={player => this.player = player} autoplay>
                                    <source type="audio/wav" src={this.state.voice}/>
                                </audio> */}
                                        <audio src={this.state.voice} autoPlay controls style={{ display: 'none' }} />
                                    </div>

                                    <div className="child-container">
                                        {
                                            this.state.student.map(item =>
                                                <div className="single-child" style={{ opacity: item.status == '2' || item.status == '3' || item.status == '4' || item.status == '5' ? '1' : '0.6' }}>
                                                    <div style={{ display: 'flex', width: '135px', marginLeft: '11px', justifyContent: 'space-between' }}>
                                                        <span style={{ fontFamily: 'PingFangSC-Regular', marginTop: '5px', width: '56px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{item.num}号</span>
                                                        <div className="single-child-top" style={{ backgroundColor: item.status == '2' || item.status == '4' || item.status == '5' ? 'rgba(41, 143, 255, 1)' : item.status == '3' ? 'rgba(255, 83, 153, 1)' : 'rgba(124, 124, 124, 1)' }}>
                                                            <span style={{ fontFamily: 'PingFangSC-Regular', color: 'rgba(255, 255, 255, 1)' }}>{item.job}</span>
                                                        </div>
                                                    </div>
                                                    <div style={{ width: '60px', height: '60px', margin: '6px 0 0 43px' }}>
                                                        <div style={{ position: 'relative' }}>
                                                            <img src={item.avatar || avatatImg} style={{ width: '60px', height: '60px', borderRadius: '50%' }} />
                                                        </div>
                                                    </div>
                                                    <div style={{ marginTop: '6px', textAlign: 'center' }}>
                                                        <span style={{ fontSize: '16px', fontFamily: 'PingFang-SC-Bold', lineHeight: '22px' }}>{item.name}</span>
                                                    </div>
                                                    <div style={{ marginTop: '2px', textAlign: 'center' }}>
                                                        {
                                                            item.status == '5'
                                                                ?
                                                                <span style={{ color: 'rgba(87, 205, 11, 1)', fontSize: '16px', fontFamily: 'PingFang-SC-Bold', lineHeight: '22px' }}>已接到</span>
                                                                :
                                                                item.status == '4'
                                                                    ?
                                                                    <span style={{ color: 'rgba(255, 25, 0, 1)', fontSize: '16px', fontFamily: 'PingFang-SC-Bold', lineHeight: '22px' }}>呼叫中</span>
                                                                    :
                                                                    item.status == '3'
                                                                        ?
                                                                        <span style={{ color: 'rgba(255, 25, 0, 1)', fontSize: '16px', fontFamily: 'PingFang-SC-Bold', lineHeight: '22px' }}>{item.realTime.length > 3 ? '' : item.realTime != '0' ? `迟到${item.realTime}分钟` : '已经迟到'}</span>
                                                                        :
                                                                        item.status == '2'
                                                                            ?
                                                                            <span style={{ color: 'rgba(87, 205, 11, 1)', fontSize: '16px', fontFamily: 'PingFang-SC-Bold', lineHeight: '22px' }}>请假中</span>
                                                                            :
                                                                            <span style={{ color: 'rgba(124, 124, 124, 1)', fontSize: '16px', fontFamily: 'PingFang-SC-Bold', lineHeight: '22px' }}>未接到</span>
                                                        }

                                                    </div>
                                                </div>
                                            )
                                        }
                                    </div>

                                </div>
                            </div>
                            :
                            <div className="login" >
                                <div className="title">班级接送信息</div>
                                <Card title='用户登录'>
                                    <Form {...layout} ref={this.formRef} style={{ width: 400 }} onFinish={this.onFinish}>
                                        <Form.Item name="user" label={'用户名'}>
                                            <Input onBlur={this.onBlur} {...this.props} onChange={this.onChange} />
                                        </Form.Item>
                                        <Form.Item name='grade' label="年级" dataSource={this.state.gradelist}>
                                            <Select
                                                style={{ marginRight: 16 }}
                                                placeholder="选择年级"
                                                allowClear
                                                onChange={value => this.gradeChange(value)}>
                                                {
                                                    this.state.gradelist.map((item, index) => <Option key={index} value={index}>{item}</Option>)
                                                }
                                            </Select>
                                        </Form.Item>

                                        <Form.Item name='clazz' label="班级">
                                            <Select
                                                placeholder="选择班级"
                                                allowClear
                                                onChange={value => { this.setState({ curstu: value }) }}>
                                                {
                                                    Array.from({ length: this.state.grade[this.state.cursel] }).map((item, index) => <Option key={index} value={index}>{index + 1}班</Option>)
                                                }
                                            </Select>
                                        </Form.Item>
                                        <Form.Item {...tailLayout}>
                                            <Button type="primary" htmlType="submit">登录</Button>
                                        </Form.Item>
                                    </Form >
                                </Card>
                            </div>
                    }
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
    }
}

export default connect(mapStateToProps, action)(withRouter(Talk))