import React, { PureComponent } from 'react'

export default class Nav extends PureComponent {
    constructor(props) {
		super(props)
    }

    render() {
		return (
			<div style={{ width: '100%', height: '100%', position: 'relative' }}>
				<div style={{ position: 'absolute', fontSize: '48px', backgroundColor: '#999', color: '#f00', width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
					正在刷新~~~
				</div>
            </div>
        )
    }
}