import { createStore, applyMiddleware } from 'redux'
import defReducer from './reducers'
import root from './sagas'
import createSagaMiddleware from 'redux-saga'
import { Map } from 'immutable'
const state = Map({
    cursel: 'main',
    weather: '',
    loginInfo: {
        userid: "",
        password: "",
        type: 0
    },
    orgList: [],
    organization: {},
    bannerList: [],
    deviceRF: {},
    liveOrder: '',
    turn_leave: {},
    call_teacher: {},
    clear: {},
    select_who: '',
    screen_change: [],
    sound: {},
    message: '',
    web_status: '',
    error_cn: 0,
    check_video: {},
    visitor: {}
});
const sagaMiddleware = createSagaMiddleware()
const store = createStore(defReducer, state, applyMiddleware(sagaMiddleware))
export default store
sagaMiddleware.run(root)