import * as types from '../types/type'

const defReducer = (state, action) => {
    switch (action.type) {

        case 'connection':
            return state.set('conid', action.payload.key)

        case types.CHANGE_MENU_SELECT:
            return state.set('cursel', action.payload)

        case types.SET_LOGIN_INFO:
            return state.set('loginInfo', action.payload)

        case types.SAVE_ORGANIZATION:
            return state.set('organization', action.payload)

        case types.ORG_LIST:
            return state.set('orgList', action.payload)

        case types.BANNER_LIST:
            return state.set('bannerList', action.payload)

        case types.DEVICE_RF:
            return state.set('deviceRF', action.payload)

        case types.LIVE_ORDER:
            return state.set('liveOrder', action.payload)
        
        case types.TURN_LEAVE:
            return state.set('turn_leave', action.payload)

        case types.CALL_TEACHER:
            return state.set('call_teacher', action.payload)

        case types.CLEAR:
            return state.set('clear', action.payload)
        
        case types.SELECT_WHO:
            return state.set('select_who', action.payload)
            
        case types.SCREEN_CHANGE:
            return state.set('screen_change', action.payload)
        
        case types.SOUND:
            return state.set('sound', action.payload)
        
        case types.MESSAGE:
            return state.set('message', action.payload)
        
        case types.WEB_STATUS:
            return state.set('web_status', action.payload)

        case types.ERROR_CN:
            return state.set('error_cn', action.payload)

        case types.CHECK_VIDEO:
            return state.set('check_video', action.payload)

        case types.VISITOR:
            return state.set('visitor', action.payload)

        default:
            return state
    }
}
export default defReducer