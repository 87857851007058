import React, { PureComponent } from 'react'
import { Table } from 'antd'
import { connect } from 'react-redux'
import action from '../../store/actions/action'

class VisitorTable extends PureComponent {

    colors = ["magenta", "red", "volcano", "orange", "gold", "lime", "green", "cyan", "blue", "geekblue", "purple"]

    columns = [
        {
            title: '访客身份',
            dataIndex: 'type',
            render: (_text, record) => (
                <span>{ record.type == '0' ? '学生家长' : '陌生人'}</span>
            )
        },
        {
            title: '来访时间',
            dataIndex: 'time'
        },
        {
            title: '访客姓名',
            dataIndex: 'name'
        },
        {
            title: '拜访对象',
            dataIndex: 'inviter'
        },
        {
            title: '电话号码',
            dataIndex: 'phone'
        },
        {
            title: '身份证',
            dataIndex: 'identity'
        },
        {
            title: '班级',
            dataIndex: 'grade'
        },
        {
            title: '事件',
            dataIndex: 'demo'
        }
    ]
    constructor(props) {
        super(props)
        this.state = {
            showModal: false,
            editStatus: '0',
            value: '',
            record: {}
        }
    }

    auditFun = (record) => {
        // this.props.emit(record.id)
        this.setState({showModal:true, record, editStatus: record.status, value: record.status == '1' ? record.info : ''})
    }
    onChange = ({ target: { value } }) => {
        this.setState({ value });
    }
    onOk = () => {
        let param = {
            id: this.state.record.id,
            account: this.state.record.account,
            name: this.state.record.name,
            time: this.state.record.time,
            info: this.state.value,
            status: '1'
        }
        this.props.emit(param)
        this.setState({showModal: false})
    }
    pageChange = page => {
        this.props.pageChange(page)
    }

    render() {
        return (
            <>
                <Table
                    columns={this.columns}
                    dataSource={this.props.orderlist}
                    showSizeChanger
                    showQuickJumper
                    onChange={this.pageChange}
                    pagination={{
                        pageSize: this.props.pageSize,
                        total: this.props.total,
                        // hideOnSinglePage: true,
                        showSizeChanger: true,
                        showQuickJumper: true,
                        pageSizeOptions: [5, 10]
                    }}
                />
            </>
        )
    }
}

const mapStateToProps = state => {
    return {
        // betonlist: state.get('betonlist')
    }
}
export default connect(mapStateToProps, action, null, { forwardRef: true })(VisitorTable)