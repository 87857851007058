import React from 'react'
import { Route, HashRouter } from 'react-router-dom'
import Login from './pages/login/login'
import Home from './pages/home/home'
import Student from './pages/student/student'
import Teacher from './pages/teacher/teacher'
import Alarm from './pages/alarm/alarm'
import Talk from './pages/talk/talk'
import { Switch } from 'react-router-dom'
import MainPage from './components/main/main'
import Check from './pages/check/check'
import Leave from './pages/leave/leave'
import Settings from './pages/settings/settings'
import Play from './pages/play/play'
import Interest from './pages/interest/interest'
import Visitor from './pages/visitor/visitor'
import Usual from './pages/usual/usual'
import Nav from './pages/nav/nav'
import Test from './pages/test/test'

class Jump extends React.Component {

    render() {
        return (
            <HashRouter>
                <Switch>
                    {/* <Route exact={true} path='/live' component={Live} /> */}
                    <Route exact={true} path='/play' component={Play} />
                    <Route exact={true} path='/test' component={Test} />
                    <Route exact={true} path='/login' component={Login} />
                    <Route exact={true} path='/talk' component={Talk} />
                    <Route exact={true} path='/nav' component={Nav} />
                    <MainPage>
                        <Route exact={true} path='/' component={Home} />
                        <Route exact={true} path='/student' component={Student} />
                        <Route exact={true} path='/teacher' component={Teacher} />
                        <Route exact={true} path='/alarm' component={Alarm} />
                        <Route exact={true} path='/settings' component={Settings} />
                        <Route exact={true} path='/check' component={Check} />
                        <Route exact={true} path='/leave' component={Leave} />
                        <Route exact={true} path='/interest' component={Interest} />
                        <Route exact={true} path='/visitor' component={Visitor} />
                        <Route exact={true} path='/usual' component={Usual} />
                    </MainPage>
                </Switch>
            </HashRouter>
        )
    }
}
export default Jump