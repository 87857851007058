function noRepeat(arr) {
	for (var i = 0; i < arr.length - 1; i++) {
		for (var j = i + 1; j < arr.length; j++) {
			if (arr[i].num == arr[j].num) {
				arr.splice(j, 1);
				j--
			}
		}
	}
	return arr
}

export default noRepeat