import React, { PureComponent } from 'react'
import { Table } from 'antd'
import { connect } from 'react-redux'
import action from '../../store/actions/action'

class CheckTable extends PureComponent {

    columns = [
        {
            title: '学生姓名',
            dataIndex: 'name'
        }, ,
        {
            title: '时间',
            dataIndex: 'time'
        },
        {
            title: '地点',
            dataIndex: 'address',
        },
        {
            title: '行为',
            dataIndex: 'behavior'
        }
    ]
    constructor(props) {
        super(props)
        this.state = {
        }
    }
    testSendMessage = async (tag) => {
        console.log('tag', tag)
        let result = await this.$axios.get('/pushUniform/' + tag)
        console.log(result.data)
    }
    render() {
        return (
            <>
                <Table
                    columns={this.columns}
                    dataSource={this.props.orderlist}
                    showSizeChanger
                    showQuickJumper
                    pagination={{
                        pageSize: this.props.pageSize,
                        total: this.props.total,
                        hideOnSinglePage: true,
                        showSizeChanger: true,
                        showQuickJumper: true
                    }}
                />
            </>
        )
    }
}

const mapStateToProps = state => {
    return {
        // betonlist: state.get('betonlist')
    }
}
export default connect(mapStateToProps, action, null, { forwardRef: true })(CheckTable)