import React, { PureComponent } from 'react'
let distanceArr = 0
let fullWidth = 0

export default class Marquee extends PureComponent {
    
    interval = null
    constructor(props) {
        super(props)
        this.state = {
            listsArr: '',
        }
    }
    componentDidMount() {
        this.setState({ listsArr: this.props.lists }, () => {
            if (this.props.lists != '') {
                this.messageFun()
            }
        })
    }
    messageFun = () => {
        this.interval && clearInterval(this.interval)
        let marqueeElementArr = document.getElementsByTagName('li')
        distanceArr = marqueeElementArr[0].offsetWidth
        let marquee = document.getElementById('marquee-box')
        fullWidth = marquee.offsetWidth + 100
        marquee.style.transform = 'translateX(' + fullWidth + 'px)'
        this.move()
    }
    componentWillReceiveProps(nextProps) {
            if (nextProps.hasOwnProperty('lists') && this.state.listsArr != nextProps.lists) {
                console.log(nextProps)
                this.setState({ listsArr: nextProps.lists }, () => this.messageFun())
                
            }
    }
    move = () => {
        let marquee = document.getElementById('marquee-box')
        let distance = 0
        this.interval = setInterval(() => {
            distance = distance - 1
            marquee.style.transform = 'translateX(' + (parseInt(fullWidth) + distance) + 'px)'
            if (-(parseInt(fullWidth) + distance) >= distanceArr) {
                distance = 0
            }
        }, 12)
    }
    render() {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <div style={{ width: '90vw', overflow: 'hidden' }}>
                    <div id="marquee-box">
                        <li style={{ listStyleType: 'none', display: 'inline', whiteSpace: 'nowrap' }}>{this.state.listsArr}</li>
                    </div>
                </div>
            </div>
        )
    }
}