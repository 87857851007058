import React, { useImperativeHandle, useEffect } from 'react'
import { Form, Input, Button, Space } from 'antd'
import { connect } from 'react-redux'
import action from '../../store/actions/action'

const layout = { labelCol: { span: 8 }, wrapperCol: { span: 10 } }
const tailLayout = { wrapperCol: { offset: 8, span: 16 } }

const Modform = React.forwardRef((props, ref) => {

    const [form] = Form.useForm()
    useEffect(() => { form.setFieldsValue({ name: props.type === 'edit' ? props.content.original : '' }) })

    const onFinish = () => { props.btfun({ name: form.getFieldValue('name') }) }

    const onReset = () => {
        form.resetFields()
        props.btclose()
    }

    useImperativeHandle(ref, () => ({
        resetFields: () => form.resetFields(),
        setField: value => form.setFields('type', value)
    }))
    return (

        <Form {...layout}
            form={form}
            name="control-hooks"
            hideRequiredMark={true}
            onFinish={onFinish}>

            <Form.Item
                name="name"
                label={'名称'}
                rules={[{ required: true, message: '不能为空' }]}>
                <Input />
            </Form.Item>

            <Form.Item {...tailLayout}>
                <Space size='middle'>
                    <Button type="primary" htmlType="submit">确定</Button>
                    <Button htmlType="button" onClick={onReset}>取消</Button>
                </Space>
            </Form.Item>
        </Form >
    )
})

const mapStateToProps = state => {
    return {}
}
export default connect(mapStateToProps, action, null, { forwardRef: true })(Modform)