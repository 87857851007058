import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import action from '../../store/actions/action'
import { Form, Button, Modal, message } from 'antd'
import InterestTable from '../../components/tables/InterestTable'
import InterestForm from '../../components/forms/interestForm'

class Interest extends PureComponent {

    formRef = React.createRef()
    constructor(props) {
        super(props)
        this.state = {
            modal2Visible: false,
            editStatus: false,
            orgList: [],
            current: 1,
            pageSize: 10,
            total: 0,
            cursel: {},
        }
        // this.props.changeMenuSelect('main')
    }

    componentDidMount = async () => {
        if (Object.keys(this.props.organization).length == 0) {
			let orgSave = window.localStorage.getItem('organization')
			if (orgSave) {
				this.props.saveOrganization(JSON.parse(orgSave))
			}
		}
        let result = await this.$axios.get('/interest/list/' + this.props.organization.account)
        if (result.code == 0) {
            let single = result.data.map(s => {
                s.grade = s.grade.split(',')
                s.weeks = s.weeks.split(',')
                return s
            })
            // single.grade = single.grade.split(',')
            // single.weeks = single.weeks.split(',')
            this.setState({ orgList: single })
        }
		this.props.changeMenuSelect('interest')
		this.props.history.push('/interest')
    }

    recFinish = (value, op) => {
        console.log(value, op)
        this.setState({ modal2Visible: true, editStatus: true, cursel: value })
        // if (op == 'edit') {
        //     this.setState({ modal2Visible: true, editStatus: true, cursel: value })
        // } else {
        //     //发送删除
        //     this.$axios.get('/organization/delete/' + value.id).then(
        //         res => {
        //             if (res.code == 0) {
        //                 message.success('删除成功')
        //                 window.location.reload()
        //             } else {
        //                 message.error('删除失败')
        //             }
        //         }).catch(err => message.error('删除失败'))
        // }
    }

    showOderList = async obj => {
        
    }
    testOk = () => {
        this.ref.refFinish()
    }
    emit = async value => {
        let params = {
            account: this.props.organization.account,
            name : value.name,
            grade: value.grade.join(),
            weeks: value.weeks.join(),
            code: value.code
        }
        params['id'] = value.id || null
        let result = await this.$axios.post('/interest/save', params)
        if (result.code == 0) {
            message.success('操作成功')
            result.data.grade = result.data.grade.split(',')
            result.data.weeks = result.data.weeks.split(',')
            let tmp = JSON.parse(JSON.stringify(this.state.orgList))
            if (params.id) {
                tmp = tmp.map(it => {
                    if (it.id == params.id) {
                        it = result.data
                    }
                    return it
                })
            } else {
                tmp.push(result.data)
            }
            this.setState({ orgList: tmp, modal2Visible: false })
        } else {
            message.error('操作失败')
        }
    }
    newOrg = () => {
        this.setState({ modal2Visible: true, editStatus: true, cursel: {} })
    }
    pageChange = async page => {
		// this.setState({ curPage: page.current, pageSize: page.pageSize })
		// this.setState({ StudentList: res.data.content, total: res.data.totalElements })
	}
    edit = async (value, op) => {
        console.log(value, op, 'edit----')
        if (op == 'edit') {
            this.setState({ modal2Visible: true, editStatus: false, cursel: value })
        } else {
            // delete
            let result = await this.$axios.get('/interest/delete/' + value.id)
            if (result.code == 0) {
                message.success('删除成功')
                let tmp = JSON.parse(JSON.stringify(this.state.orgList))
                tmp = tmp.filter(item => item.id != value.id)
                this.setState({ orgList: tmp, modal2Visible: false })
            } else {
                message.error('删除失败')
            }
        }
    }
    render() {
        return (
            <div className="operate" >
                <Form
                    style={{ justifyContent: 'flex-end', marginBottom: '8px' }}
                    layout={'inline'}
                    name="control-hooks"
                    hideRequiredMark={true}
                    onFinish={this.showOderList}>
                    <Form.Item>
                        <Button type="primary" onClick={this.newOrg}>新增兴趣班</Button>
                    </Form.Item>
                </Form>

                <div className="radius-style shadow-style" style={{ padding: 8 }} >
                    <InterestTable
                        edit={this.edit}
                        pageChange={this.pageChange}
                        pageSize={this.state.pageSize}
                        total={this.state.orgList.length}
                        orderlist={this.state.orgList}
                        recFinish={this.recFinish} />
                </div>

                <Modal
                    title={this.state.editStatus ? '编辑兴趣班' : '新增兴趣班'}
                    width={600}
                    centered
                    visible={this.state.modal2Visible}
                    okText='确定'
                    cancelText='取消'
                    onOk={this.testOk}
                    onCancel={() => { 
                        this.setState({ modal2Visible: false })
                        this.ref.resetFields()
                    }}>
                    <InterestForm
                        item={this.state.cursel}
                        type={this.state.editStatus}
                        emit={this.emit}
                        ref={node => { this.ref = node }}
                    />
                </Modal>
            </div >
        )
    }
}

const mapStateToProps = state => {
    return {
        organization: state.get('organization')
    }
}

export default connect(mapStateToProps, action)(Interest)