import React from 'react'
// import 'antd/dist/antd.css'
import { List, Space, Button, Card, Popconfirm, Modal, message } from 'antd'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import action from '../../store/actions/action'
import ExplainsForm from '../../components/forms/explainsform'

class Usual extends React.Component {

	formRef = React.createRef()

	constructor(props) {
		super(props)
		this.state = {
			strangerList: [],
			studentList: [],
			editName: false,
			modifyContent: '',
			modifyType: '',
			showType: 'add'
		}
	}

	async componentDidMount() {
		if (Object.keys(this.props.organization).length == 0) {
			let orgSave = window.localStorage.getItem('organization')
			if (orgSave) {
				this.props.saveOrganization(JSON.parse(orgSave))
			}
		}
		let list = await this.$axios.get('/explain/get')
		if (list.code == 0) {
			let tmp = list.data
			let stu = []
			let str = []
			tmp.forEach(it => {
				if (it.type == '0') {
					stu.push(it)
				} else {
					str.push(it)
				}
			})
			this.setState({ strangerList: str, studentList: stu })
		}

		this.props.changeMenuSelect('usual')
		this.props.history.push('/usual')
	}

	deleteMedia = (vl, ty) => {
		this.$axios.get('/explain/delete/' + vl.id).then(res => {
			if (res.code == 0) {
				if (ty == 'str') {
					let tmp = this.state.strangerList
					tmp = tmp.filter(it => it.id != vl.id)
					this.setState({ strangerList: tmp })
				} else {
					let tmp = this.state.studentList
					tmp = tmp.filter(it => it.id != vl.id)
					this.setState({ studentList: tmp })
				}
				message.success('操作成功')
			}
		})
	}

	btfun = vl => {

		let params = {
			type: this.state.modifyType == 'stu' ? '0' : '1',
			info: vl.name
		}
		if (this.state.showType == 'edit') {
			params['id'] = this.state.modifyContent.id
		}
		/*if (this.state.modifyType == 'str') {
			this.$axios.post('/explain/save', params).then(res => this.setState({ fileList: res.data }))
		} else {
			this.$axios.post('/explain/save', params).then(res => this.setState({ pictList: res.data }))
		}*/
		//console.log(vl, this.state.showType, this.state.modifyType)
		//console.log(params)
		this.$axios.post('/explain/save', params).then(res => {
			if (res.code == 0) {
				if (this.state.showType == 'edit') {
					if (this.state.modifyType == 'stu') {
						let tmp = this.state.studentList
						tmp = tmp.map(it => {
							if (it.id == params.id) {
								it.info = params.info
							}
							return it
						})
						this.setState({ studentList: tmp })
					} else {
						let tmp = this.state.strangerList
						tmp = tmp.map(it => {
							if (it.id == params.id) {
								it.info = params.info
							}
							return it
						})
						this.setState({ strangerList: tmp })
					}
				} else {
					if (this.state.modifyType == 'stu') {
						let tmp = this.state.studentList
						tmp.push(res.data)
						this.setState({ studentList: tmp })
					} else {
						let tmp = this.state.strangerList
						tmp.push(res.data)
						this.setState({ strangerList: tmp })
					}
				}
				message.success('操作成功！')
			}
		})
		this.setState({ editName: false })
	}

	editFun = (vl, ty) => {
		this.setState({ editName: true, modifyContent: vl, modifyType: ty, showType: 'edit' })
	}

	render() {
		return (
			<div style={{ display: 'flex', width: '100%' }}>
				<div style={{ display: 'flex', width: '60%', flexDirection: 'column' }}>
					<div style={{ padding: '16px' }}>设置</div>
					<div style={{ display: 'flex' }}>
						<Card
							title="陌生人"
							hoverable
							extra={<Button onClick={() => this.setState({ editName: true, showType: 'add', modifyType: 'str' })}>添加</Button>}
							style={{ flex: 1, marginRight: '16px' }}>
							<List
								pagination={{ pageSize: 8 }}
								bordered
								dataSource={this.state.strangerList}
								renderItem={(item, index) => (
									<List.Item
										key={index}
										extra={
											<Space size='small'>
												<a onClick={() => this.editFun(item, 'str')}>编辑</a>
												<Popconfirm
													title='确认要删除？'
													okText='是'
													cancelText='否'
													onConfirm={() => this.deleteMedia(item, 'str')}>
													<a href='#'>删除</a>
												</Popconfirm>
											</Space>
										}>
										<span>{item.info}</span>
									</List.Item>)}
							/>
						</Card>

						<Card
							title="学生家长"
							hoverable
							extra={<Button onClick={() => this.setState({ editName: true, showType: 'add', modifyType: 'stu' })}>添加</Button>}
							style={{ flex: 1 }}>
							<List pagination={{ pageSize: 8 }} bordered dataSource={this.state.studentList}
								renderItem={(item, index) => (
									<List.Item
										key={index}
										extra={
											<Space size='small'>
												<a onClick={() => this.editFun(item, 'stu')}>编辑</a>
												<Popconfirm
													title='确认要删除？'
													okText='是'
													cancelText='否'
													onConfirm={() => this.deleteMedia(item, 'stu')}>
													<a href='#'>删除</a>
												</Popconfirm>
											</Space>
										}>
										<span>{item.info}</span>
									</List.Item>)}
							/>
						</Card>

						<Modal
							title="编辑"
							centered
							footer={null}
							visible={this.state.editName}
							onCancel={() => { this.setState({ editName: false }) }}>
							<ExplainsForm type={this.state.showType} content={this.state.modifyContent} btfun={this.btfun} />
						</Modal>
					</div>
				</div>

			</div>

		)
	}
}

const mapStateToProps = state => {
	return {
		organization: state.get('organization')
	}
}

export default connect(mapStateToProps, action)(withRouter(Usual))
