import React from 'react'
// import 'antd/dist/antd.css'
import { List, Space, Upload, Button, Card, Popconfirm, Modal, Form, Switch, TimePicker, Select, Image, message, Tag } from 'antd'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import action from '../../store/actions/action'
import { UploadOutlined } from '@ant-design/icons'
import { Player } from 'video-react'
import "video-react/dist/video-react.css"
import Modform from '../../components/forms/modform'
import moment from 'moment'
import store from '../../store'

const { Option } = Select
const layout = { labelCol: { span: 7 }, wrapperCol: { offset: 3, span: 8 } }
const timeLayout = { labelCol: { span: 10 }, wrapperCol: { offset: 2, span: 6 } }
class Leave extends React.Component {

	formRef = React.createRef()

	constructor(props) {
		super(props)
		this.state = {
			fileList: [],
			pictList: [],
			progList: [],
			pptList: [],
			progShow: [],
			showMoadl: false,
			seeModal: false,
			timeModal: false,
			type: 'video',
			delay: 0,
			time: '',
			only: true,
			previewVideo: false,
			videoSrc: '',
			previewPicture: false,
			pictureSrc: '',
			editName: false,
			modifyContent: {},
			modifyType: '',
			progModal: '',
			// begTime: '00:00:00',
			seeItem: '',
			begTime: '',
			endTime: '',
			timeList: [],
			orgList: {},
			lock: false,
			checkOnce: false
		}
	}
	componentDidMount() {
		if (Object.keys(this.props.organization).length == 0) {
			let orgSave = window.localStorage.getItem('organization')
			if (orgSave) {
				this.props.saveOrganization(JSON.parse(orgSave))
			}
		}
		setTimeout(() => {
			this.$axios.get('/video/list/' + this.props.organization['account']).then(res => this.setState({ fileList: res.data }))
			this.$axios.get('/picture/list/' + this.props.organization['account']).then(res => this.setState({ pictList: res.data }))
			this.$axios.get('/ppt/list/' + this.props.organization['account']).then(res => this.setState({ pptList: res.data }))
			this.$axios.get('/program/list/' + this.props.organization['account']).then(res => this.setState({ progList: res.data }))
			this.$axios.get('/recover/list/' + this.props.organization['account']).then(res => {
				if (res.code == 0) {
					this.setState({ timeList: res.data })
				}
			})
			this.$axios.get('/organization/all').then(res => {
				if (res.code == 0) {
					res.data.map(item => {
						if (item.account == this.props.organization['account']) {
							this.setState({ orgList: item }, () => console.log('uuuuuuuuu', this.state.orgList))
						}
					})

				}
			})

			this.listener = store.subscribe(() => {
				let web = store.getState().get('web_status')
				let select = store.getState().get('select_who')
				let check = store.getState().get('check_video')
				if (Object.keys(web).length > 0 && select == 'web') {
					// if (this.state.orgList) {
					//     let org = JSON.parse(JSON.stringify(this.state.orgList))
					//     org.map(s => {
					//         if (s.account == web.account) {
					//             this.setState({ orgList: s }, () => console.log('uuuuuuuuu', this.state.orgList))
					//         }
					//     })

					// }
					if (web.account == this.state.orgList.account) {
						this.setState({ orgList: web }, () => console.log('uuuuuuuuu', this.state.orgList))
					}
				}
				
				if (Object.keys(check).length > 0 && select == 'check') {
					if (check.account == this.state.orgList.account) {
						console.log('-------', select, check)
						if (!this.state.checkOnce) {
							this.setState({ checkOnce: true })
							if (check.message == '') {
								this.$axios.get('/video/list/' + this.props.organization['account']).then(res => this.setState({ fileList: res.data }))
							} else {
								message.error(check.result)
								setTimeout(() => this.$axios.get('/video/list/' + this.props.organization['account']).then(res => this.setState({ fileList: res.data })), 1000)
							}
							setTimeout(() => this.setState({ checkOnce: false }), 100)
						}
					}

				}
			})
		}, 0)

		this.props.changeMenuSelect('leave')
		this.props.history.push('/leave')
	}
	componentWillUnmount() {
		this.listener()
	}
	onChange = info => {
		if (info.file.status === 'done') {
			if (info.file.response.code == 0) {
				this.$axios.get('/video/list/' + this.props.organization['account']).then(res => this.setState({ fileList: res.data }))
			} else {
				message.error(info.file.response.message)
			}
		}
	}
	beforeUpload = (file, fileList) => {
		console.log(file.size)
		if (file.size >= 5*1024*1024*1024) {
			message.error('视频最大为5G！')
			return false
		}
		// if (this.state.fileList.length >= 5) {
		// 	message.error('最大上传数为5个！')
		// 	return false
		// }
		return true
	}

	omChange = info => {
		if (info.file.status === 'done') {
			if (info.file.response.code == 0) {
				this.$axios.get('/picture/list/' + this.props.organization['account']).then(res => this.setState({ pictList: res.data }))
			} else {
				message.error(info.file.response.message)
			}
		}
	}

	ceforeUpload = (file, fileList) => {
		console.log(file.size)
		if (file.size >= 5*1024*1024) {
			message.error('图片最大为5M！')
			return false
		}
		// if (this.state.pictList.length >= 100) {
		// 	message.error('最大上传数为100张！')
		// 	return false
		// }
		return true
	}

	opChange = info => {
		if (info.file.status === 'done') {
			if (info.file.response.code == 0) {
				this.$axios.get('/ppt/list/' + this.props.organization['account']).then(res => this.setState({ pptList: res.data }))
			} else {
				message.error(info.file.response.message)
			}
		}
	}
	peforeUpload = (value) => {
		// if (this.state.pptList.length >= 10) {
		// 	message.error('最大上传数为10个！')
		// 	return false
		// }
		return true
	}

	timeChange = (time, timeString) => {
		console.log(time, timeString)
		this.setState({ time: timeString })
	}

	handleChange = value => {
		this.setState({ progShow: value })
	}

	switchChange = value => {
		this.formRef.current.setFieldsValue({ delay: 0, video: [] })
		this.setState({ type: value, progShow: [], delay: 0, only: value == 'picture' ? false : true })
	}

	delayFun = (e) => {
		this.setState({ delay: e.target.value })
	}

	onFinish = () => {
		this.state.time = '00:00:00'
		let params = {
			time: this.state.time,
			orglist: this.state.progShow.toString().replace(/,/g, " ") || "ok",
			type: this.state.type,
			delay: this.state.delay * 1,
			account: this.props.organization['account']
		}

		if (this.state.time != '') {
			if (this.state.type != 'leave') {
				if (params.orglist == 'ok') {
					message.error('请选择要播放的文件')
					return
				}
			}
			if (this.state.type == 'picture') {
				if (!!(this.state.delay * 1) == false) {
					params.delay = 5
				}
			}
			this.$axios.post('/program/save', params).then(res => {
				if (res.code == 0) {
					this.setState({ progList: res.data, showMoadl: false, time: '' })
					this.formRef.current.resetFields()
					this.setState({ type: 'video' })
				} else {
					message.error('操作失败')
				}
			})

			console.log(params)
		} else {
			message.error('请选择开始时间')
		}
	}

	deleteMedia = (vl, ty) => {
		if (ty == 'video') {
			this.$axios.get('/video/delete/' + this.props.organization['account'] + '/' + vl.id).then(res => this.setState({ fileList: res.data }))
		} else if (ty == 'picture') {
			this.$axios.get('/picture/delete/' + this.props.organization['account'] + '/' + vl.id).then(res => this.setState({ pictList: res.data }))
		} else if (ty == 'ppt') {
			this.$axios.get('/ppt/delete/' + this.props.organization['account'] + '/' + vl.id).then(res => this.setState({ pptList: res.data }))
		} else if (ty == 'pro') {
			this.$axios.get('/program/delete/' + vl.id).then(res => {
				// this.setState({ progList: res.data })
				if (res.code == 0) {
					let tmp = this.state.progList.filter(it => it.id != vl.id)
					this.setState({ progList: tmp })
				}
			})
		}
	}

	previewFun = (vl, ty) => {
		if (ty == 'video') {
			this.setState({ previewVideo: true, videoSrc: vl.normal })
		} else if (ty == 'picture') {
			this.setState({ previewPicture: true, pictureSrc: vl.normal })
		}
	}

	editFun = (vl, ty) => {
		this.setState({ editName: true, modifyContent: vl, modifyType: ty })
	}

	btfun = vl => {
		let params = {
			id: this.state.modifyContent.id,
			original: vl.name,
			normal: this.state.modifyContent.normal,
			account: this.state.modifyContent.account
		}
		if (this.state.modifyType == 'video') {
			this.$axios.post('/video/edit/' + this.props.organization['account'], params).then(res => this.setState({ fileList: res.data }))
		} else if (this.state.modifyType == 'ppt') {
			this.$axios.post('/ppt/edit/' + this.props.organization['account'], params).then(res => this.setState({ pptList: res.data }))
		} else {
			this.$axios.post('/picture/edit/' + this.props.organization['account'], params).then(res => this.setState({ pictList: res.data }))
		}
		this.setState({ editName: false })
	}

	editProgram = (ty, vl) => {
		// this.setState({ begTime: vl == '' ? '00:00:00' : vl.time, progModal: ty, showMoadl:true })
		console.log('iiiiiiiiiiiii', ty, vl)
		if (ty == 'add') {
			this.setState({ progModal: ty, showMoadl: true })
		} else {
			this.setState({ seeModal: true, seeItem: vl })
		}
	}

	leaveChange = (time, timeString, status) => {
		if (status == 'beg')
			this.setState({ begTime: timeString })
		else
			this.setState({ endTime: timeString })
	}

	onOk = async () => {

		if (this.state.begTime != '' && this.state.endTime != '') {
			let result = await this.$axios.post('/recover/add', {
				account: this.props.organization['account'],
				segment: this.state.begTime + ' ' + this.state.endTime
			})
			if (result.code == 0) {
				this.setState({ timeModal: false, timeList: result.data })
				message.success('操作成功')
			} else {
				message.error('操作失败')
			}
		}
	}

	delTime = async (item) => {
		let result = await this.$axios.get('recover/del/' + item.id)
		if (result.code == 0) {
			this.setState({ timeList: result.data })
			message.success('删除成功')
		} else {
			message.error('删除失败')
		}
	}

	lockChange = async () => {
		// let res = await React.$axios.post('/organization/saveByAccount', {account: props.organization.account, voice: lock ? 0 : 1})
		// if (res.code == 0)  setLock(!lock)
		this.setState({ lock: !this.state.lock })
	}

	refresh = async () => {
		let res = await this.$axios.get('/organization/refresh/' + this.props.organization['id'])
		if (res.code == 0) {
			message.success('刷新成功')
		} else {
			message.error('刷新失败')
		}
	}

	ShutDown = () => {
		this.$axios.get('/shutdown/' + this.props.organization['account'])
	}

	render() {
		return (
			<div style={{ display: 'flex' }}>
				<div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
					<div style={{ display: 'flex' }}>
						<Card
							title="视频列表"
							hoverable
							extra={
								<Upload
									action={'https://jiesong.sanlongedu.com/api/fileUpload?type=video&account=' + this.props.organization['account']}
									listType='text'
									accept='video/*'
									maxCount={1}
									name='file'
									onChange={(fileList) => this.onChange(fileList)}
									beforeUpload={this.beforeUpload}>
									<Button><UploadOutlined />上传视频</Button>
								</Upload>
							}
							style={{ flex: 1, marginLeft: '16px', marginRight: '16px' }}>
							<List
								pagination={{ pageSize: 5 }}
								bordered
								dataSource={this.state.fileList}
								renderItem={(item, index) => (
									<List.Item
										key={index}
										extra={
											<Space size='small'>
												<a onClick={() => this.editFun(item, 'video')}>编辑</a>
												<Popconfirm
													title='确认要删除？'
													okText='是'
													cancelText='否'
													onConfirm={() => this.deleteMedia(item, 'video')}>
													<a href='#'>删除</a>
												</Popconfirm>
											</Space>
										}>
										<a onClick={() => this.previewFun(item, 'video')}>{item.original + (item.audit == 1 ? '' : '(审核中...)') }</a>
									</List.Item>)}
							/>
						</Card>

						<Card
							title="图片列表"
							hoverable
							extra={
								<Upload
									action={'https://jiesong.sanlongedu.com/api/fileUpload?type=picture&account=' + this.props.organization['account']}
									listType='text'
									accept='image/*'
									maxCount={1}
									name='file'
									onChange={(fileList) => this.omChange(fileList)}
									beforeUpload={this.ceforeUpload}>
									<Button>
										<UploadOutlined />上传图片
									</Button>
								</Upload>
							}
							style={{ flex: 1, marginLeft: '16px', marginRight: '16px' }}>
							<List pagination={{ pageSize: 5 }} bordered dataSource={this.state.pictList}
								renderItem={(item, index) => (
									<List.Item
										key={index}
										extra={
											<Space size='small'>
												<a onClick={() => this.editFun(item, 'picture')}>编辑</a>
												<Popconfirm
													title='确认要删除？'
													okText='是'
													cancelText='否'
													onConfirm={() => this.deleteMedia(item, 'picture')}>
													<a href='#'>删除</a>
												</Popconfirm>
											</Space>
										}>
										<a onClick={() => this.previewFun(item, 'picture')}>{item.original}</a>
									</List.Item>)}
							/>
						</Card>

						<Card
							title="PPT列表"
							hoverable
							extra={
								<Upload
									action={'https://jiesong.sanlongedu.com/api/fileUpload?type=ppt&account=' + this.props.organization['account']}
									listType='text'
									accept='ppt/*'
									maxCount={1}
									name='file'
									onChange={(fileList) => this.opChange(fileList)}
									beforeUpload={this.peforeUpload}>
									<Button>
										<UploadOutlined />上传文件
									</Button>
								</Upload>
							}
							style={{ flex: 1, marginLeft: '16px', marginRight: '16px' }}>
							<List pagination={{ pageSize: 5 }} bordered dataSource={this.state.pptList}
								renderItem={(item, index) => (
									<List.Item
										key={index}
										extra={
											<Space size='small'>
												<a onClick={() => this.editFun(item, 'ppt')}>编辑</a>
												<Popconfirm
													title='确认要删除？'
													okText='是'
													cancelText='否'
													onConfirm={() => this.deleteMedia(item, 'ppt')}>
													<a href='#'>删除</a>
												</Popconfirm>
											</Space>
										}>
										<span>{item.original}</span>
									</List.Item>)}
							/>
						</Card>

						<Card title="节目列表" hoverable
							extra={
								<Space>
									<Popconfirm
										title='确认要刷新大屏？'
										okText='是'
										cancelText='否'
										onConfirm={() => this.refresh()}>
										<Button type='primary'>刷新大屏</Button>
									</Popconfirm>
									<Button onClick={() => this.editProgram('add', '')}>添加节目</Button>
								</Space>
							}
							style={{ flex: 1, marginLeft: '16px', marginRight: '16px' }}>
							<List pagination={{ pageSize: 5 }} bordered dataSource={this.state.progList}
								renderItem={(item, index) => (
									<List.Item
										key={index}
										extra={
											<Space size='small'>
												<a onClick={() => this.editProgram('edit', item)}>查看</a>
												<Popconfirm
													title='确认要删除？'
													okText='是'
													cancelText='否'
													onConfirm={() => this.deleteMedia(item, 'pro')}>
													<a href='#'>删除</a>
												</Popconfirm>
											</Space>
										}>
										<span>{item.type == 'video' ? '播放视频' : item.type == 'picture' ? '播放图片' : '播放PPT'}</span>
									</List.Item>)}
							/>
						</Card>
					</div>
					<div style={{ display: 'flex', marginTop: '16px' }}>
						<div style={{ display: 'flex', width: '50%' }}>
							<Card title="放学信息展示时间" hoverable
								extra={<Button onClick={() => this.setState({ timeModal: true })}>添加时间段</Button>}
								style={{ flex: 3, marginLeft: '16px', marginRight: '16px' }}>
								<List pagination={{ pageSize: 4 }} bordered dataSource={this.state.timeList}
									renderItem={(item, index) => (
										<List.Item
											key={index}
											extra={
												<Space size='small'>
													<Popconfirm
														title='确认要删除？'
														okText='是'
														cancelText='否'
														onConfirm={() => this.delTime(item)}>
														<a href='#'>删除</a>
													</Popconfirm>
												</Space>
											}>
											<span>{item.segment}</span>
										</List.Item>)}
								/>
							</Card>

							<Card title="大屏软件监控" hoverable style={{ flex: 2, marginRight: '16px' }}>
								{/* <Tag color={ this.state.orgList.join == 0 ? '#f50' : this.state.orgList.join == 1 ? '#108ee9' : this.state.orgList.join == 2 ? '#ff5500' : '#108ee9' }>{ this.state.orgList.join == 0 ? '断开' : this.state.orgList.join == 1 ? '正常' : this.state.orgList.join == 2 ? '断开' : '正常' }</Tag>
								<Tag color={ this.state.orgList.join == 0 ? '#f50' : this.state.orgList.join == 1 ? '#ff5500' : this.state.orgList.join == 2 ? '#108ee9' : '#108ee9' }>{ this.state.orgList.join == 0 ? '断开' : this.state.orgList.join == 1 ? '断开' : this.state.orgList.join == 2 ? '正常' : '正常' }</Tag> */}
								<div style={{ marginBottom: '16px' }}>
									<Space>
										<span>网络状态：</span>
										<Tag color={this.state.orgList.join != 3 ? '#f50' : '#108ee9'}>{this.state.orgList.join != 3 ? '断开' : '正常'}</Tag>
									</Space>
								</div>
								<div style={{ marginBottom: '16px' }}>
									<Popconfirm
										title='确认要关机？'
										okText='是'
										cancelText='否'
										onConfirm={() => this.ShutDown()}>
										<a href='#'>远程关闭</a>
									</Popconfirm>
								</div>
							</Card>
						</div>
					</div>
				</div>
				<Modal
					visible={this.state.showMoadl}
					centered
					okText='确定'
					title={this.state.progModal == 'add' ? "添加节目" : "编辑节目"}
					onOk={this.onFinish}
					onCancel={() => {
						this.formRef.current.resetFields()
						this.setState({ showMoadl: false, time: '' })
					}}>
					<Form {...layout} ref={this.formRef} name="control-pass" hideRequiredMark={true}>

						<Form.Item name="switch" label="操作类型">
							<Select onChange={this.switchChange}>
								<Option value='video'>视频</Option>
								<Option value='picture'>图片</Option>
								<Option value='ppt'>PPT</Option>
							</Select>
						</Form.Item>
						<Form.Item name="video" label="文件列表">
							<Select mode="multiple" placeholder="选择文件" onChange={this.handleChange} value={this.state.progShow} style={{ width: '100%' }}>
								{this.state.type == 'video' ? this.state.fileList.map(item => item.audit == 1 && <Option key={item.normal}>{item.original}</Option>) : (this.state.type == 'picture' ? this.state.pictList.map(item => <Option key={item.normal}>{item.original}</Option>) : this.state.pptList.map(item => <Option key={item.normal}>{item.original}</Option>))}
							</Select>
						</Form.Item>
						{/* <Form.Item name="delay" label="延时时间（秒）">
							<Input readOnly={this.state.only} onChange={value => this.delayFun(value)} />
						</Form.Item> */}
					</Form>
				</Modal>

				<Modal title={"视频预览"} centered footer={null} visible={this.state.previewVideo}
					onCancel={() => {
						this.setState({ previewVideo: false })
						this.player.pause()
					}}>
					<Player playsInline autoPlay={true} ref={player => this.player = player} poster="/assets/poster.png" src={this.state.videoSrc} />
				</Modal>

				<Modal title={"图片预览"} centered footer={null} visible={this.state.previewPicture} onCancel={() => { this.setState({ previewPicture: false }) }}>
					<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
						<Image src={this.state.pictureSrc} />
					</div>
				</Modal>

				<Modal title={this.state.seeItem.type == 'video' ? '播放视频' : this.state.seeItem.type == 'picture' ? '播放图片' : '播放PPT' } centered footer={null} visible={this.state.seeModal} onCancel={() => { this.setState({ seeModal: false }) }}>
					<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
						<List pagination={{ pageSize: 4 }} bordered dataSource={this.state.seeItem.original} style={{ width: '100%' }}
							renderItem={(item, index) => (
								<List.Item key={index}>
									<span>{item}</span>
								</List.Item>)}
						/>
					</div>
				</Modal>
				<Modal
					visible={this.state.timeModal}
					centered
					okText='确定'
					title="添加时间点"
					onOk={this.onOk}
					hideRequiredMark={true}
					onCancel={() => {
						this.setState({ timeModal: false })
					}}>
					<Form {...timeLayout} name="control-pass" hideRequiredMark={true}>
						<Form.Item name="beg" label="开始时间">
							<TimePicker onChange={(time, timeString) => this.leaveChange(time, timeString, 'beg')} />
						</Form.Item>
						<Form.Item name="end" label="结束时间">
							<TimePicker onChange={(time, timeString) => this.leaveChange(time, timeString, 'end')} />
						</Form.Item>
					</Form>
				</Modal>
				<Modal
					title="编辑"
					centered
					footer={null}
					visible={this.state.editName}
					onCancel={() => { this.setState({ editName: false }) }}>
					<Modform type='edit' content={this.state.modifyContent} btfun={this.btfun} />
				</Modal>
			</div>
		)
	}
}

const mapStateToProps = state => {
	return {
		organization: state.get('organization')
	}
}

export default connect(mapStateToProps, action)(withRouter(Leave))
