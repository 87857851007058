import ReconnectingWebSocket from "reconnecting-websocket"
import { takeEvery, fork, all } from "redux-saga/effects"
import * as types from '../types/type'
import store from '../../store'

let rws = null;

async function initWebsocket() {
	rws = new ReconnectingWebSocket('wss://jiesong.sanlongedu.com/wss/websocket')
	// rws = new ReconnectingWebSocket('ws://localhost:7000/websocket')
	rws.addEventListener("open", () => {
		if (!rws) return
	});
	rws.addEventListener("message", e => {
		store.dispatch({type: types.ERROR_CN, payload: 0})
		
		if (e.data) {
			if (e.data != 'heart') {
				let rec = JSON.parse(e.data)
				if (rec == 'heart') return
				if (rec.hasOwnProperty('device')) {
					store.dispatch({ type: types.DEVICE_RF, payload: JSON.parse(rec.device) })
				} else if (rec.hasOwnProperty('live')) {
					store.dispatch({ type: types.LIVE_ORDER, payload: rec.live })
				} else if (rec.hasOwnProperty('turn')) {
					store.dispatch({ type: types.SELECT_WHO, payload: 'turn' })
					store.dispatch({ type: types.TURN_LEAVE, payload: rec })
				} else if (rec.hasOwnProperty('call')) {
					store.dispatch({ type: types.SELECT_WHO, payload: 'call' })
					store.dispatch({ type: types.CALL_TEACHER, payload: rec })
				} else if (rec.hasOwnProperty('clear')) {
					store.dispatch({ type: types.SELECT_WHO, payload: 'clear' })
					store.dispatch({ type: types.CLEAR, payload: rec })
				} else if (rec.hasOwnProperty('screen')) {
					store.dispatch({ type: types.SELECT_WHO, payload: 'screen' })
					store.dispatch({ type: types.SCREEN_CHANGE, payload: rec })
				} else if (rec.hasOwnProperty('sound')) {
					store.dispatch({ type: types.SELECT_WHO, payload: 'sound' })
					store.dispatch({ type: types.SOUND, payload: rec })
				} else if (rec.hasOwnProperty('message')) {
					store.dispatch({ type: types.SELECT_WHO, payload: 'message' })
					store.dispatch({ type: types.MESSAGE, payload: rec })
				} else if (rec.hasOwnProperty('join')) {
					store.dispatch({ type: types.SELECT_WHO, payload: 'web' })
					store.dispatch({ type: types.WEB_STATUS, payload: rec })
				} else if (rec.hasOwnProperty('empty')) {
					store.dispatch({ type: types.SELECT_WHO, payload: 'empty' })
				} else if (rec.hasOwnProperty('check')) {
					store.dispatch({ type: types.SELECT_WHO, payload: 'check' })
					store.dispatch({ type: types.CHECK_VIDEO, payload: rec })
				} else if (rec.hasOwnProperty('visitor')) {
					store.dispatch({ type: types.SELECT_WHO, payload: 'visitor' })
					store.dispatch({ type: types.VISITOR, payload: rec })
				}
			}
		}
	})
	rws.addEventListener("error", e => { console.error("error", e.message); })
	rws.addEventListener("close", () => { console.info("asset service disconnected.") })
}

function Connect() { initWebsocket() }

function Disconnect() {
	rws.removeEventListener('open')
	rws.removeEventListener('message')
	rws.removeEventListener('error')
	rws.removeEventListener('close')
	rws.close()
}

function Send(data) { rws.send(JSON.stringify(data.data)) }
function* connectSaga() { yield takeEvery(types.CONNECT_WEBSOCKET, Connect) }
function* disconnectSaga() { yield takeEvery(types.DISCONNECT_WEBSOCKET, Disconnect) }
function* sendSaga() { yield takeEvery(types.SEND_WEBSOCKET, Send) }

export default function* root() { yield all([fork(connectSaga), fork(disconnectSaga), fork(sendSaga)]) }