import React, { useEffect, useState } from 'react'
import { Form, Input, Button, Card, Select, message, Space, Modal, TimePicker, Switch, DatePicker } from 'antd'
import { connect } from 'react-redux'
import action from '../../store/actions/action'
import moment from 'moment'
import TextArea from 'rc-textarea'
import MyEditor from '../../components/other/MyEditor'
import dayjs from 'dayjs'

const { RangePicker } = DatePicker
const layout = { labelCol: { span: 12 }, wrapperCol: { offset: 2, span: 6 } }
const tailLayout = { wrapperCol: { offset: 14, span: 6 } }
const timeLayout = {labelCol: { span: 10 }, wrapperCol: { offset:2, span: 6}}
const messLayout = {labelCol: { span: 4 }, wrapperCol: { offset: 2, span: 16 }}
let gradeSet = {}
let saveList = []
let timeList = []
const gradeList = ["一年级", "二年级", "三年级", "四年级", "五年级", "六年级"]
const { Option } = Select
const Settings = (props) => {

    const [form] = Form.useForm()
    const [formRef] = Form.useForm()
    const [recover] = Form.useForm()
    const [formMes] = Form.useForm()
    const [cn, setCn] = useState(-1)
    const [grade, setGrade] = useState([])
    const [clazzList, setClazzList] = useState([])
    const [recoverlist, setRecoverlist] = useState([])
    const [showModal, setShowModal] = useState(false)
    const [begTime, setBegTime] = useState()
    const [endTime, setEndTime] = useState()
    const [timeIndex, setTimeIndex] = useState(-1)
    const [face, setFace] = useState(1)
    const [lock, setLock] = useState(true)
    const [msgShow, setMsgShow] = useState(false)
    const [messageText, setMessageText] = useState('')
    const [messageBegTime, setMessageBegTime] = useState('')
    const [messageEndTime, setMessageEndTime] = useState('')
    const [enable, setEnable] = useState(true)

    useEffect(() => {
        if (Object.keys(props.organization).length == 0) {
			let orgSave = window.localStorage.getItem('organization')
			if (orgSave) {
				props.saveOrganization(JSON.parse(orgSave))
			}
		}
        React.$axios.get('/grade/list/' + props.organization.account).then(res => {
            if (res.code == 0) {
                gradeSet = res.data
                let grade = JSON.parse(res.data.grade)
                setGrade(grade)
                saveList = JSON.parse(res.data.clazz)
                grade.map((item, index) => {
                    let gind = `grade${index}`
                    form.setFieldsValue({ [gind]: item })
                })
                
                if (!!gradeSet.segment) {
                    let timeseg = JSON.parse(gradeSet.segment)
                    setMessageBegTime(timeseg[0])
                    setMessageEndTime(timeseg[1])
                } else {
                    setMessageBegTime(moment().format('YYYY-MM-DD HH:mm:ss'))
                    setMessageEndTime(moment().endOf('hour').format('YYYY-MM-DD HH:mm:ss'))
                }
                setEnable(gradeSet.enable == '1' ? true : false)
                let message = gradeSet.message
                setMessageText(message)
                
            }
        })
        React.$axios.get('/recover/list/' + props.organization.account).then(res => {
            if (res.code == 0) {
                let tmp = []
                res.data.map(it => tmp.push(it.segment))
                setRecoverlist(tmp)
                timeList = res.data
            }
        })
        setFace(props.organization.face)
        setLock(props.organization.voice == 1 ? true : false)

        props.changeMenuSelect('settings')
		props.history.push('/settings')

        console.log(formMes, 'formMes')
    }, [])

    const addTime = () => {
        setShowModal(true)
    }
    const delTime = async () => {
        // console.log('del', timeList = res.data)
        if (timeList.length > 0 && timeIndex >= 0) {
            let result = await React.$axios.get('recover/del/' + timeList[timeIndex].id)
            if (result.code == 0) {
                timeList = result.data
                let tmp = []
                result.data.map(it => tmp.push(it.segment))
                setRecoverlist(tmp)
                setTimeIndex(-1)
                message.success('删除成功')
            } else {
                message.error('删除失败')
            }
        } else {
            message.error('请选择时间')
        }
    }

    const timeChange = (time, timeString, status) => {
        if (status == 'beg')
            setBegTime(timeString)
        else
            setEndTime(timeString)
    }
    const messageTimeChange = (value,vstr) => {
        setMessageBegTime(vstr[0])
        setMessageEndTime(vstr[1])
    }
    const messageTextChange = ({ target: { value } }) => {
        setMessageText(value)
        if(value.length >= 300) {
            message.info('长度限制300字')
        }
    }

    const onOk = async () => {
        
        if (begTime != '' && endTime != '') {
            let result = await React.$axios.post('/recover/add', {
                account: props.organization.account,
                segment: begTime + ' ' + endTime
            })
            if (result.code == 0) {
                let tmp = recoverlist
                tmp.push(begTime + ' ' + endTime)
                setRecoverlist(tmp)
                setShowModal(false)
                timeList = result.data
                message.success('操作成功')
            } else {
                message.error('操作失败')
            }
            console.log(begTime, endTime)
        }
    }

    const timeSelect = value => {
        console.log(value)
        setTimeIndex(value)
    }

    const onFinish = values => {
        console.log(saveList)
        let arr = []
        arr.push(values['grade0'])
        arr.push(values['grade1'])
        arr.push(values['grade2'])
        arr.push(values['grade3'])
        arr.push(values['grade4'])
        arr.push(values['grade5'])

        let clz = []
        arr.map((item, cn) => {
            let sig = []
            for (let i = 0; i < parseInt(item); i++) {
                
                if (saveList && saveList[cn] && saveList[cn].length > i)  sig.push(saveList[cn][i])
                else sig.push('0')
            }
            clz.push(sig)
        })

        let params = {}
        if (gradeSet.hasOwnProperty('id')) {
            params = {
                id: gradeSet.id,
                orgid: props.organization.account,
                grade: JSON.stringify(arr),
                clazz: JSON.stringify(clz)
            }
        } else {
            params = {
                orgid: props.organization.account,
                grade: JSON.stringify(arr),
                clazz: JSON.stringify(clz)
            }
        }
        React.$axios.post('/grade/add', params).then(res => {
            if (res.code == 0) {
                gradeSet = res.data
                setGrade(JSON.parse(res.data.grade))
                saveList = JSON.parse(res.data.clazz)
                
                if (parseInt(cn) >= 0) {
                    if (arr[cn] != clazzList.length) {
                        let tmpList = []
                        console.log('cn', cn, clz[cn].length)
                        for (let i = 0; i < clz[cn].length; i++) {
                            tmpList.push({
                                name: (i+1)+'班:',
                                value: clz[cn][i]
                            })
                        }
                        setClazzList(tmpList)
                    }
                }
                message.success('设置成功！')
            } else {
                message.error('设置失败！')
            }
        })
    }

    const changeClass = value => {
        setCn(value)
        let tmpList = []
        if (parseInt(value) >= 0) {
            for (let i = 0; i < grade[value]; i++) {
                tmpList.push({
                    name: (i+1)+'班:',
                    value: saveList[value][i]
                })
            }
        }
        setClazzList(tmpList)
    }

    const onChange = (e, index) => {
        let tmpList = JSON.parse(JSON.stringify(clazzList))
        tmpList[index].value = e.target.value
        setClazzList(tmpList)
    }

    const Save = () => {
        console.log(clazzList)
        let tmpList = []
        for (let i = 0; i < clazzList.length; i++) {
            tmpList.push(clazzList[i]['value'])
        }
        saveList[cn] = tmpList
        console.log(saveList)

        let params = {
            id: gradeSet.id,
            orgid: props.organization.account,
            grade: gradeSet.grade,
            clazz: JSON.stringify(saveList)
        }
        React.$axios.post('/grade/add', params).then(res => {
            if (res.code == 0) {
                gradeSet = res.data
                setGrade(JSON.parse(res.data.grade))
                saveList = JSON.parse(res.data.clazz)
                message.success('设置成功！')
            } else {
                message.error('设置失败！')
            }
        })
    }
    const saveFace = async value => {
        let res = await React.$axios.post('/organization/saveByAccount', {account: props.organization.account, face: value})
        if (res.code == 0)  setFace(value)
    }

    const lockChange = async () => {
        let res = await React.$axios.post('/organization/saveByAccount', {account: props.organization.account, voice: lock ? 0 : 1})
        if (res.code == 0)  setLock(!lock)
    }

    const messageFinish = () => {
        console.log(messageText, messageBegTime, messageEndTime)
        if (messageBegTime == '' || messageEndTime == '') {
            message.error('请填写完整')
        } else {
            let params = Object.assign({}, gradeSet, {
                segment: JSON.stringify([messageBegTime, messageEndTime]),
                message: messageText,
                enable: enable ? '1' :'0'
            })
            React.$axios.post('/grade/add', params).then(res => {
                if (res.code == 0) {
                    gradeSet = res.data
                    setMsgShow(false)
                    message.success('设置成功！')
                } else {
                    message.error(res.message)
                }
            })
        }
    }

    const myshowMsg = () => {
        setMsgShow(true)
        formMes.setFieldsValue({ cmes: gradeSet.message })
        let mes = formMes.getFieldsValue('cmes')
        console.log(mes)
    }

    const parentFun = e => {
        console.log(e, 'toParentEvt')
        setTimeout(() => {
            setMessageText(e)
        }, 0)
        
    }

    return (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div style={{ display: 'flex' }}>
                <Card title="年级设置" style={{ width: '320px', marginRight: '16px' }}>
                    <Form {...layout} form={form} name="control-hooks" onFinish={onFinish}>
                        {
                            gradeList.map((item, index) =>
                                <Form.Item name={"grade" + index} label={item + "班级个数"} rules={[{ required: true }]}>
                                    <Input type="number" />
                                </Form.Item>
                            )
                        }
                        <Form.Item {...tailLayout}>
                            <Button type="primary" htmlType="submit">确定</Button>
                        </Form.Item>
                    </Form>
                </Card>
                <Card title="教室设置" style={{ flex: 1 }} 
                    extra={
                        <Select
                            style={{ width: 120 }}
                            placeholder="选择年级"
                            allowClear
                            onChange={value => changeClass(value)}
                        >
                            {
                                gradeList.map((item, index) => <Option key={index} value={index}>{item}</Option>)
                            }
                        </Select>
                    }
                >
                    <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%'}}>
                        <div style={{display: 'flex', flexWrap: 'wrap'}}>
                        {
                            clazzList.map((item, index) => 
                                <div style={{display: 'flex', alignItems: 'center', width: '140px', textAlign: 'center', marginBottom: '8px'}}>
                                    <div style={{width:'60px'}}>{clazzList[index].name}</div>
                                    <Input onChange={(e) => onChange(e, index) } value={clazzList[index].value} style={{width:'60px'}}/>
                                </div>
                            )
                        }
                        </div>
                        {
                            clazzList.length > 0 && <div style={{textAlign: 'right' }}><Button type="primary" onClick={() => Save()}>保存</Button></div>
                        }
                    </div>
                    
                </Card>
            </div>
            <div style={{ marginTop: '16px', display: 'flex' }}>
                {/* <Card title="放学状态" style={{width: '480px'}}>
                    <Form layout='inline' form={recover} name="control-hooks">
                        <Form.Item name='recover' label="时间点" dataSource={recoverlist}>
                            <Select
                                style={{ width: 160, marginRight: 16 }}
                                placeholder="时间点"
                                onChange={value => timeSelect(value)}
                                allowClear>
                                {
                                    recoverlist.map((item, index) => <Option key={index} value={index}>{item}</Option>)
                                }
                            </Select>
                        </Form.Item>
                        <Form.Item>
                            <Space>
                               <Button type="primary" onClick={() => addTime()}>添加</Button>
                               <Button type="primary" onClick={() => delTime()}>删除</Button> 
                            </Space>
                            
                        </Form.Item>
                    </Form>
                </Card> */}
                <Modal 
					visible={showModal} 
					centered 
					okText='确定' 
					title="添加时间点"
					onOk={onOk} 
                    hideRequiredMark={true}
					onCancel={() => {
						//formRef.current.resetFields()
						setShowModal(false)
					}}>
					<Form {...timeLayout} form={formRef} name="control-pass" hideRequiredMark={true}>
						<Form.Item name="beg" label="开始时间">
							<TimePicker onChange={(time, timeString) => timeChange(time, timeString, 'beg')} />
						</Form.Item>
                        <Form.Item name="end" label="结束时间">
							<TimePicker onChange={(time, timeString) => timeChange(time, timeString, 'end')} />
						</Form.Item>
					</Form>
				</Modal>
				<Card title="大屏选择">
                    {face == 0 ?
                    <Space>
						<Button type='primary' onClick={() => saveFace(0)}>样式一</Button>
						<Button type='default' onClick={() => saveFace(1)}>样式二</Button>
					</Space>
                    :
                    <Space>
						<Button type='default' onClick={() => saveFace(0)}>样式一</Button>
						<Button type='primary' onClick={() => saveFace(1)}>样式二</Button>
					</Space>}
                </Card>
                <Card title="语音播报"style={{marginLeft:'16px'}}>
                    <Switch checked={lock} onChange={() => lockChange()} />
                </Card>
                <Card title="通知"style={{marginLeft:'16px'}}>
                    <Button type='primary' onClick={myshowMsg}>设置</Button>
                </Card>
                <Modal width={1200} title='通知' visible={msgShow} centered onCancel={() => setMsgShow(false)} hideRequiredMark={true} onOk={messageFinish}>
                    <Form {...messLayout} form={formMes}  name="control-hooks" hideRequiredMark={true}>
                        <Form.Item name="start" label="时间段">
                            <RangePicker
                                defaultValue={[dayjs(messageBegTime), dayjs(messageEndTime)]}
                                showTime={{ format: 'HH:mm:ss' }}
                                format="YYYY-MM-DD HH:mm:ss"
                                onChange={(value, vstr) => messageTimeChange(value, vstr)}/>
						</Form.Item>
                        <Form.Item name="cmes" label="通知内容">
                            <MyEditor con={messageText} toParentEvt={parentFun}/>
						</Form.Item>
                        <Form.Item name="enable" label="开启">
                            <Switch checked={enable} onChange={() => setEnable(!enable)} />
						</Form.Item>
					</Form>
                </Modal>
            </div>
        </div>
        
    )
}

const mapStateToProps = (state) => {
    return {
        cursel: state.get('cursel'),
        organization: state.get('organization')
    }
}

export default connect(mapStateToProps, action)(Settings)