import React from 'react'
import { connect } from 'react-redux'
import action from '../../store/actions/action'
import { Form, Select, message, Button, Input } from 'antd'
import StudentTable from '../../components/tables/StudentTable'
import noRepeat from '../../utils/tool'
import XLSX from 'xlsx'

const { Option } = Select

class Student extends React.Component {

	formRef = React.createRef()
	constructor(props) {
		super(props)

		this.state = {
			isVisible: false,
			type: 'create',
			item: '',
			who: 'beton',
			gradelist: ["一年级", "二年级", "三年级", "四年级", "五年级", "六年级"],
			partlist: [],
			showlist: [],
			modal2Visible: false,
			cursel: -1,
			curstu: '',
			StudentList: [],
			grade: '',
			curPage: 1,
			pageSize: 10,
			total: 0
		}
		this.props.changeMenuSelect('student')
	}
	componentDidMount = async () => {
		if (Object.keys(this.props.organization).length == 0) {
			let orgSave = window.localStorage.getItem('organization')
			if (orgSave) {
				this.props.saveOrganization(JSON.parse(orgSave))
			}
		}
		this.props.changeMenuSelect('student')
		this.props.history.push('/student')
		let res = await this.$axios.get('/grade/list/' + this.props.organization.account)
		if (res.code == 0) {
			this.setState({ grade: JSON.parse(res.data.grade) })
		}
	}

	searchFun = async () => {
		if (parseInt(this.state.curstu) >= 0) {
			let params = {
				account: this.props.organization.account,
				grade: this.state.cursel,
				clazz: this.state.curstu,
				curPage: 1,
				pageSize: this.state.pageSize
			}
			let range = this.formRef.current.getFieldValue('range')
			if (range && range.trim().length > 0) {
				params.range = range
			}
			let res = await this.$axios.post('/person/find/student', params)
			if (res.code == 0) {
				this.setState({ StudentList: res.data.content, total: res.data.totalElements })
			}
		} else {
			message.error('请选择年级班级')
		}
	}

	exportFun = async () => {
		let params = {
			user: this.props.organization.account,
			grade: this.state.cursel,
			clazz: this.state.curstu
		}
		let res = await this.$axios.post('/person/find/allStudent', params)
		if (res.code == 0) {
			let studentList = noRepeat(res.data)
			let arr = [
				['学生姓名', '学号'], ['', '']
			]
			studentList.map(item => arr.push([
				item.name,
				item.num,
				item.phone
			]))
			if (studentList.length > 0) {
				// 将数组转化为标签页
				var ws = XLSX.utils.aoa_to_sheet(arr);
				// 创建工作薄
				var wb = XLSX.utils.book_new()
				// 将标签页插入到工作薄里
				XLSX.utils.book_append_sheet(wb, ws)
				// 将工作薄导出为excel文件
				XLSX.writeFile(wb, this.props.organization.name + this.state.gradelist[this.state.cursel] + (this.state.curstu+1) + '班.xlsx');
			} else {
				message.info("没有可用数据")
			}
		}
		
	}

	emit = id => {
		this.$axios.get('/person/delete/' + id).then(res => {
			if (res.code == 0) {
				this.searchFun()
			} else {
				message.error("删除失败！")
			}
		})

	}

	gradeChange = value => {
		this.setState({ cursel: value, curstu: -1 }, () => {
			this.formRef.current.setFieldsValue({ clazz: [] })
		})
	}
	pageChange = async page => {
		this.setState({ curPage: page.current, pageSize: page.pageSize })
		let params = {
			account: this.props.organization.account,
			grade: this.state.cursel,
			clazz: this.state.curstu,
			curPage: page.current,
			pageSize: page.pageSize
		}
		let res = await this.$axios.post('/person/find/student', params)
		if (res.code == 0) {
			this.setState({ StudentList: res.data.content, total: res.data.totalElements })
		}
	}
	render() {
		return (
			<div style={{ display: 'flex', flexDirection: 'column', height: '100%', width: '100%' }}>
				<Form
					style={{ justifyContent: 'flex-end', marginBottom: '8px' }}
					layout={'inline'}
					ref={this.formRef}
					name="control-hooks"
					hideRequiredMark={true}
					onFinish={this.showOderList}>
					<Form.Item name='grade' label="年级" dataSource={this.state.gradelist}>
						<Select
							style={{ width: 120, marginRight: 16 }}
							placeholder="选择年级"
							allowClear
							onChange={value => this.gradeChange(value)}>
							{
								this.state.gradelist.map((item, index) => <Option key={index} value={index}>{item}</Option>)
							}
						</Select>
					</Form.Item>

					<Form.Item name='clazz' label="班级">
						<Select style={{ width: 120 }}
							placeholder="选择班级"
							allowClear
							onChange={value => { this.setState({ curstu: value }) }}>
							{
								Array.from({ length: this.state.grade[this.state.cursel] }).map((item, index) => <Option key={index} value={index}>{index + 1}班</Option>)
							}
						</Select>
					</Form.Item>

					<Form.Item name='range' label="学生姓名">
						<Input placeholder="请输入关键字查询" />
					</Form.Item>
					<Form.Item>
						<Button type="default" onClick={() => this.exportFun()}>导出</Button>
					</Form.Item>
					<Form.Item>
						<Button type="primary" onClick={() => this.searchFun()}>查询</Button>
					</Form.Item>
				</Form>

				<div className="radius-style shadow-style" style={{ padding: 8 }} >
					<StudentTable
						emit={this.emit}
						pageChange={this.pageChange}
						pageSize={this.state.pageSize}
						total={this.state.total}
						orderlist={this.state.StudentList}
						recFinish={this.recFinish} />
				</div>
			</div >
		)
	}
}

const mapStateToProps = (state) => {
	return {
		cursel: state.get('cursel'),
		organization: state.get('organization')
	}
}
export default connect(mapStateToProps, action)(Student)