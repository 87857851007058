import React from 'react'
import { connect } from 'react-redux'
import action from '../../store/actions/action'
import { Form, Select, message, Button, Input } from 'antd'
import VisitorTable from '../../components/tables/VisitorTable'

class Visitor extends React.Component {
	
	formRef = React.createRef()
	constructor(props) {
		super(props)

		this.state = {
			alarmList: [],
			curPage: 1,
			pageSize: 10,
			total: 0
		}
	}
	componentDidMount = async () => {
		if (Object.keys(this.props.organization).length == 0) {
			let orgSave = window.localStorage.getItem('organization')
			if (orgSave) {
				this.props.saveOrganization(JSON.parse(orgSave))
			}
		}
		this.props.changeMenuSelect('visitor')
		this.props.history.push('/visitor')

		let params = {
			account: this.props.organization.account,
			curPage: this.state.curPage,
			pageSize: this.state.pageSize
		}
		let res = await this.$axios.post('/invite/list', params)
		if (res.code == 0)	{
			this.setState({ alarmList: res.data.content, total: res.data.totalElements })
		}
	}
	
	searchFun = async () => {
		
	}
	pageChange = async page => {
		this.setState({ curPage: page.current, pageSize: page.pageSize })
		let params = {
			account: this.props.organization.account,
			curPage: page.current,
			pageSize: page.pageSize
		}
		let res = await this.$axios.post('/invite/list', params)
		if (res.code == 0)	{
			this.setState({ alarmList: res.data.content, total: res.data.totalElements })
		}
	}

	render() {
		return (
			<div style={{ display: 'flex', flexDirection: 'column', height: '100%', width: '100%' }}>
				<Form
					style={{ justifyContent: 'flex-end', marginBottom: '8px' }}
					layout={'inline'}
					ref={this.formRef}
					name="control-hooks"
					hideRequiredMark={true}
					onFinish={this.showOderList}>

					<Form.Item name='range' label="查询条件">
						<Input placeholder="请输入关键字查询" />
					</Form.Item>

					<Form.Item>
						<Button type="primary" onClick={() => this.searchFun()}>查询</Button>
					</Form.Item>
				</Form>

				<div className="radius-style shadow-style" style={{ padding: 8 }} >
					<VisitorTable
						pageChange={this.pageChange}
						pageSize={this.state.pageSize}
						total={this.state.total}
						orderlist={this.state.alarmList}
						recFinish={this.recFinish} />
				</div>
			</div >
		)
	}
}

const mapStateToProps = (state) => {
	return {
		cursel: state.get('cursel'),
		organization: state.get('organization')
	}
}
export default connect(mapStateToProps, action)(Visitor);