import React, { PureComponent } from 'react'
import { Table, Tag, Popconfirm, Space } from 'antd'
import { connect } from 'react-redux'
import action from '../../store/actions/action'

class TeacherTable extends PureComponent {

    colors = ["magenta", "red", "volcano", "orange", "gold", "lime", "green", "cyan", "blue", "geekblue", "purple"]

    columns = [
        {
            title: '姓名',
            dataIndex: 'name'
        },
        {
            title: '手机号',
            dataIndex: 'phone'
        },
        {
            title: '管理班级',
            dataIndex: 'realGrade'
        },
        {
            title: '操作',
            key: 'action',
            render: (_text, record) => (
                <Space>
                    <Popconfirm
                        title='确认要删除改记录？'
                        okText='是'
                        cancelText='否'
                        onConfirm={() => this.auditFun(record, 'del')}
                        onCancel={this.onCancel}>
                        <a href="#">删除</a>
                    </Popconfirm>
                    {record.grade != '000000' && <div> <a onClick={() => this.auditFun(record, 'aud')}>{ record.audit == '0' ? '审核通过' : '取消资格'}</a></div>}
                    <div>
                        <a onClick={() => this.auditFun(record, 'man')}>{ record.manager == 0 ? '设为管理员' : '取消管理员'}</a>
                    </div>
                </Space>
                
            ),
        },
    ]
    constructor(props) {
        super(props)
        this.state = {
        }
    }

    auditFun = (record, type) => {
        this.props.emit(record, type)
    }
    onChange = (page, pageSize) => {
        console.log('page index', page, pageSize)
        this.setState({pageSize: page.pageSize})
        this.props.pageChange(page)
    }

    render() {
        return (
            <>
                <Table
                    columns={this.columns}
                    dataSource={this.props.orderlist}
                    showSizeChanger
                    showQuickJumper
                    onChange={this.onChange}
                    pagination={{
                        pageSize: this.props.pageSize,
                        total: this.props.total,
                        hideOnSinglePage: true,
                        showSizeChanger: true,
                        showQuickJumper: true
                    }}
                />
            </>
        )
    }
}

const mapStateToProps = state => {
    return {
        // betonlist: state.get('betonlist')
    }
}
export default connect(mapStateToProps, action, null, { forwardRef: true })(TeacherTable)